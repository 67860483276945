import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';

@Injectable({
  providedIn: 'root',
})
export class PainelService extends BaseService {
  getPaineis() {
    return this.get(`totem-web/painel`);
  }
}
