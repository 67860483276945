import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-modal-default',
  templateUrl: './modal-default.component.html',
  styleUrls: ['./modal-default.component.scss'],
})
export class ModalDefaultComponent {
  @Input() title: string;
  @Input() description: string;
  @Output() yes: EventEmitter<any> = new EventEmitter();
  @Output() no: EventEmitter<any> = new EventEmitter();
  @Output() buttonClose: EventEmitter<any> = new EventEmitter();

  @HostListener('document:keydown', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ) {
    if (event.key === 'Escape' || event.key === 'Esc') {
      this.selectNo();
    }
  }

  constructor() {}

  selectYes() {
    this.yes.emit(true);
  }

  selectNo() {
    this.no.emit(false);
  }
}
