import { HttpErrorResponse } from '@angular/common/http';
import { Component, ComponentRef, Input } from '@angular/core';
import { CommentsChatComponent } from '../comments-chat/comments-chat.component';
import { IComment } from '../../models/posts.model';
import { CommentService } from '../../services/comment/comment.service';
import { DomService } from '../../services/dom/dom.service';
import { Toast } from '../../utils/toast/toast';
import { environment } from 'projects/clinicas-liv/src/environments/environment';

@Component({
  selector: 'comments-wrapper',
  templateUrl: './comments-wrapper.component.html',
  styleUrls: ['./comments-wrapper.component.scss'],
})
export class CommentsWrapperComponent {
  @Input() schenduling_id: string;
  @Input() quantity: number | null;
  @Input() token: string;
  @Input() base_url: string;

  opened: boolean;
  comments: IComment[];
  shimmers: number[];
  ref: ComponentRef<CommentsChatComponent>;

  constructor(
    private service: CommentService,
    private toast: Toast,
    private dom: DomService
  ) {
    this.opened = false;
    this.comments = [];
    this.shimmers = [1, 2, 3, 4];
    this.schenduling_id = '';
    this.quantity = 0;
  }

  fetchComments() {
    this.ref = this.dom.appendComponentToBody<CommentsChatComponent>(
      CommentsChatComponent,
      {
        schenduling_id: this.schenduling_id,
        comments: this.comments,
        shimmers: this.shimmers,
        close: this.onClose,
      }
    );
    this.service.getAll(this.schenduling_id).subscribe(
      (result: IComment[]) => {
        this.comments = result;
        this.shimmers = [];
        this.quantity = result.length;
        this.setRefData();
        this.scrollToBottom();
      },
      (error: HttpErrorResponse) => {
        this.toast.error({ title: '', message: 'Observações não carregadas' });
      }
    );
  }

  setRefData() {
    const _ref = this.ref as any;
    _ref.instance.setData({
      comments: this.comments,
      shimmers: this.shimmers,
      schenduling_id: this.schenduling_id,
      ref: this.ref,
      append: this.append,
      quantity: this.quantity,
    });
  }

  onClose() {
    this.ref.destroy();
  }

  append(comment: IComment) {
    this.comments.push(comment);
    this.quantity = this.comments.length;
  }

  scrollToBottom() {
    setTimeout(() => {
      const element = document.querySelector('#display-container') as any;
      if (element) {
        element.scrollTo(0, element.scrollHeight - element.offsetHeight);
      }
    }, 100);
  }
}
