import { BaseService } from './../base/base.service';
import { Email } from './../../models/email.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EmailService extends BaseService {

  post(model: Email) {
    return this._post<Email>('/v3/clinic/emails', model);
  }

  patch(model: Email) {
    return this._patch<Email>(`/v3/clinic/emails/${model.id}`, model);
  }

  delete(model: Email) {
    return this._delete<Email>(`/v3/clinic/emails/${model.id}`);
  }

}
