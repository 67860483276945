import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

interface Select {
  value: string;
  label: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  @Input() label: string;
  @Input() disableChangeInit: boolean;
  @Input() dados: Select[] = [];
  @Input() defaultValue: any;
  @Input() selectId: string;
  @Input() registerSelected: any;
  @Input() disabled: boolean = false;
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  // Inputs abaixo serão usados para deixar o id e descrição dos valores dinamicos
  @Input() inputId: string;
  @Input() descricao: string;
  @Input() isLoading: boolean = false;
  // Quando for true setará valor inicial sem alterações.
  @Input() hasValueDefault: boolean;

  @Input() public enableOnChange: boolean = true;

  constructor() {}

  ngOnInit(): void {
    this.setValorPadrao();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.dados &&
      !this.hasValueDefault &&
      this.dados &&
      this.dados.length > 0
    ) {
      this.registerSelected = this.tratarRetorno(this.dados[0]);
      this.emitRegistroClicado(this.registerSelected);
    }

    if (changes.defaultValue && this.hasValueDefault) {
      this.emitRegistroClicado(this.defaultValue);
    }
  }

  setEnableOnChange(): void {
    this.enableOnChange = true;
  }

  setValorPadrao() {
    this.registerSelected = this.defaultValue;
    if (!this.disableChangeInit) {
      this.emitRegistroClicado(this.registerSelected);
    }
  }

  emitRegistroClicado(event) {
    let dadosArray = this.dados?.find((element) => {
      return event == this.tratarRetorno(element);
    });

    if (event && this.enableOnChange) {
      this.onChange.emit(dadosArray);
    }
  }

  tratarRetorno(value) {
    return value.value ? value.value : value[this.inputId];
  }
}
