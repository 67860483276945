<section class="header">
  <app-botao-voltar [path]="go_back_path"></app-botao-voltar>
  <section class="header__menu">
    <h1>{{ title }}</h1>
    <ul *ngIf="has_add_button">
      <li class="nav-item dropdown">
        <button
          class="nav-link dropdown-toggle"
          id="navbarDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Cadastrar
        </button>
        <div
          class="dropdown-menu dropdown-menu-right dropdown-menu-lg-left"
          aria-labelledby="navbarDropdown"
        >
          <button class="dropdown-item" (click)="openModal()">
            Particular/Não beneficiário
          </button>
        </div>
      </li>
    </ul>
    <ul *ngIf="n1_selected || n2_selected">
      <li (click)="navigateToSchedule()" [ngClass]="{ selected: n1_selected }">
        <i class="fas fa-users"></i> Lista de Prestadores
      </li>
      <li
        (click)="navigateToServiceLocation()"
        [ngClass]="{ selected: n2_selected }"
      >
        <i class="fas fa-hospital"></i> Locais de Atendimento
      </li>
    </ul>
  </section>
</section>
