import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minutes'
})
export class MinutesPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    const [, minutes ] = value.split(':');
    return `${minutes}min`;
  }

}
