import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  private set(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  private get(key: string) {
    return localStorage.getItem(key);
  }

  private setSession(key: string, value: string) {
    sessionStorage.setItem(key, value);
  }

  private getSession(key: string) {
    return sessionStorage.getItem(key);
  }

  setString(key: string, value: string) {
    this.set(key, value);
  }

  setNumber(key: string, value: Number) {
    this.set(key, String(value));
  }

  setList(key: string, value: any[]) {
    this.set(key, JSON.stringify(value));
  }

  getString(key: string): string {
    return this.get(key);
  }

  getNumber(key: string) {
    return Number(this.get(key));
  }

  getObject(key: string) {
    let obj = this.get(key);
    return JSON.parse(obj);
  }

  setObject(key: string, value: any) {
    this.set(key, JSON.stringify(value));
  }

  getObjectSession(key: string) {
    let obj = this.getSession(key);
    return JSON.parse(obj);
  }

  setObjectSession(key: string, value: any) {
    this.setSession(key, JSON.stringify(value));
  }

  getList(key: string) {
    let obj = this.get(key);
    return JSON.parse(obj);
  }

  clear() {
    localStorage.clear();
  }
}
