import { PainelService } from './../../core/services/painel/painel.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'projects/clinicas-liv/src/app/shared/services/storage/storage.service';
import { environment } from 'projects/clinicas-liv/src/environments/environment';
import { GuicheService } from '../../core/services/guiche/guiche.service';
import { LoginService } from '../../core/services/login/login.service';
import { StateHeaderService } from '../../shared/services/state-header/state-header.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public usuario: string;
  public senha: string;
  public visualizarSenha: boolean;
  public loadingRequest: boolean;
  public mensagemErro: string;
  public openDialogChangelog: boolean;
  public version: string;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private storageService: StorageService,
    private guicheService: GuicheService,
    private stateHeaderService: StateHeaderService,
    private painelService: PainelService
  ) {
    this.version = environment.version;
  }

  ngOnInit(): void {}

  openModalChangelog() {
    this.openDialogChangelog = true;
  }

  closeModalChangelog() {
    this.openDialogChangelog = false;
  }

  setVersion(version: string): void {
    this.version = version;
  }

  salvarDadosLogin(res) {
    this.storageService.processaLogin(res);
  }

  formularioValido() {
    return this.usuario && this.senha ? true : false;
  }

  formEnter(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.logarNoSistema();
    }
  }

  logarNoSistema() {
    if (this.formularioValido()) {
      this.loadingRequest = true;
      this.loginService
        .login({ username: this.usuario, password: this.senha })
        .subscribe(
          (res) => {
            this.getGuiches();
            this.getPaineis();
            this.salvarDadosLogin(res);
            this.router.navigate(['/inicio']);
            this.loadingRequest = false;
          },
          (err) => {
            this.loadingRequest = false;
            this.mensagemErro = err.error.message
              ? err.error.message
              : 'Erro desconhecido, entre em contato com o suporte!';
          }
        );
    } else {
      this.mensagemErro = "'Preencha todos os campos!'";
    }
  }

  getGuiches() {
    this.guicheService.getGuiches().subscribe((res) => {
      this.storageService.setGuiches(res.rows);
      this.stateHeaderService.guiches = this.storageService.getGuiches();
    });
  }

  getPaineis() {
    this.painelService.getPaineis().subscribe((res) => {
      this.storageService.setPaineis(res);
      this.stateHeaderService.paineis = this.storageService.getPaineis();
    });
  }

  mostrarSenha() {
    this.visualizarSenha = !this.visualizarSenha;
  }
}
