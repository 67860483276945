import { Phone } from './../../models/phone.model';
import { Injectable } from '@angular/core';
import { BaseService } from './../base/base.service';

@Injectable({
  providedIn: 'root'
})
export class PhoneService extends BaseService {

  post(model: Phone) {
    return this._post<Phone>('/v3/clinic/phones', model);
  }

  patch(model: Phone) {
    return this._patch<Phone>(`/v3/clinic/phones/${model.id}`, model);
  }

  delete(model: Phone) {
    return this._delete<Phone>(`/v3/clinic/phones/${model.id}`);
  }

}
