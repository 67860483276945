import { Component, ComponentRef, Input } from '@angular/core';

@Component({
  selector: 'app-comments-chat',
  templateUrl: './comments-chat.component.html',
  styleUrls: ['./comments-chat.component.scss'],
})
export class CommentsChatComponent {
  @Input() shimmers: any[] = [];
  @Input() schenduling_id: string = '';
  @Input() comments: any[] = [];
  @Input() quantity: number | null;
  @Input() close: () => void = () => {};

  data = {
    shimmers: [],
    scheduling_id: '',
    comments: [],
    quantity: null,
    ref: {} as ComponentRef<unknown>,
    append: (data: any) => {},
  };

  constructor() {}

  onClose() {
    this.data.ref.destroy();
  }

  setData(data: any) {
    this.data = data;
  }

  scrollToBottom() {
    setTimeout(() => {
      const element = document.querySelector('#display-container') as any;
      if (element) {
        element.scrollTo(0, element.scrollHeight - element.offsetHeight);
      }
    }, 100);
  }

  onAppend(event: any) {
    this.data.append(event);
    this.scrollToBottom();
  }
}
