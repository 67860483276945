import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'weekday'
})
export class WeekdayPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {
    const days = new Map<number, string>();
    days.set(0, 'Domingo');
    days.set(1, 'Segunda');
    days.set(2, 'Terça');
    days.set(3, 'Quarta');
    days.set(4, 'Quinta');
    days.set(5, 'Sexta');
    days.set(6, 'Sabádo');

    return days.get(value);
  }

}
