import { Recipient } from './../../../../../recipient/models/recipient.model';
import { Pregnancy } from './../models/pregnancy.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../../../../../environments/environment'


@Injectable({
  providedIn: 'root'
})
export class PregnancyService {

  url = `${environment.apiV3}/v3`

  constructor(private httpClient: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  }

  get(): Observable<Pregnancy[]> {
    return this.httpClient.get<Pregnancy[]>(this.url + '/clinic/pregnancies')

  }

  getById(id: string): Observable<Pregnancy> {
    return this.httpClient.get<Pregnancy>(this.url + '/clinic/pregnancies/' + id)
  }



  getBeneficiary(recipient_id: string) {
    return this.httpClient.get<[]>(
        `${this.url} /clinic/pregnancies/?recipient_id=${recipient_id}`,
        this.httpOptions
      )
  }

  delete(pregnancy: Pregnancy) {
    return this.httpClient.delete<Pregnancy>(this.url + '/' + pregnancy.id, this.httpOptions)
  }

  create(pregnancy: Pregnancy): Observable<Pregnancy> {
    return this.httpClient.post<Pregnancy>(`${this.url}/clinic/pregnancies`, (pregnancy), this.httpOptions)
  }

  update(recipient:Recipient ,pregnancy: Pregnancy): Observable<Pregnancy>{
    console.log("pregnancy-->",pregnancy)
    return this.httpClient.patch<Pregnancy>(`${this.url}/clinic/pregnancies/${recipient.pregnancies.id}`,
    pregnancy)
  }
}
