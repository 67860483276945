import { Injectable } from '@angular/core';
import { AgendamentoService } from 'projects/clinicas-liv/src/app/core/services/agendamento/agendamento.service';
import { LocalAtendimentoService } from '../../../core/services/local-atendimento/local-atendimento.service';
import { PathService } from '../../../core/services/path/path.service';
import { Agendamento } from '../../models/agendamento';
import { Beneficiario } from '../../models/beneficiario';
import { FiltroAgendamento } from '../../models/filtro-agendamento';
import { OrderPipe } from 'ngx-order-pipe';


@Injectable({
  providedIn: 'root',
})
export class StateListaAgendamentoService {
  // Váriaveis de estado para lista de agendamentos
  public loading: boolean = false;
  public agendamentos: Agendamento[] = [];
  public pagina: number = 1;
  public totalRegistros: number;
  public status: any[] = [];
  public limite: number = 50;
  public houveAlteracaoNosDados: boolean;
  // Váriaveis de estado para lista de agendamentos Fim

  public filtro = {} as FiltroAgendamento;

  // Parametros de envio de agendamento
  public paramsAgendamento = {} as Agendamento;
  public paramsBeneficiario = {} as Beneficiario;
  // Parametros de envio de agendamento Fim

  constructor(
    private ambulatorioService: LocalAtendimentoService,
    private pathService: PathService,
    private agendamentoService: AgendamentoService,
    private orderPipe: OrderPipe
  ) {}

  limparParamsAgendamento() {
    this.paramsAgendamento = {} as Agendamento;
  }

  getAgendamento(agendamento_id: string) {
    this.agendamentoService.getAgendamento(agendamento_id).subscribe(
      (res) => {
        this.paramsAgendamento = res;
        this.paramsAgendamento.preenchimentoAutomaticoAgendado = true;
        this.houveAlteracaoNosDados = false;
      },
      (err) => {}
    );
  }

  notAgendaAberta(beneficiarioId) {
    return beneficiarioId ? true : false;
  }

  agendamentoClicado(item: Agendamento, callback) {
    this.paramsAgendamento = item;
    if (this.notAgendaAberta(item.beneficiario?.id)) {
      // Quando a agenda estiver preenchida, enviar para tela de escolha do beneficiário, mostrando opção para desmarcar.
      this.paramsAgendamento.preenchimentoAutomaticoAgendado = true;
      if (callback) {
        callback();
      }
    } else {
      // Quando a agenda estiver disponível, enviar para tela de escolha do beneficiário.
      this.paramsAgendamento.preenchimentoAutomaticoEmAberto = true;
      this.pathService.navigateBuscaBeneficiario();
    }
  }

  getAgendamentosRequest() {
    this.loading = true;
    this.agendamentos = [];

    this.ambulatorioService
      .getAtendimentos(this.filtro, this.limite, this.pagina, false)
      .subscribe(
        (res) => {
          this.agendamentos = this.orderPipe.transform(res.rows, 'hora_agendamento');
          this.loading = false;
          this.totalRegistros = res.total;
          this.status = res.status;
        },
        (err) => {
          this.loading = false;
        }
      );
  }
}
