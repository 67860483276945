<div id="Header">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
        <img src="/assets/images/header-logo.svg" alt="logo" class="liv-logo cursor-pointer"
          (click)="navigateInicio()" />
      </div>

      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex justify-content-end">
        <section class="actions">
          <div class="container-btn">
            <div class="dropdown tot">
              <button
                class="user_config"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                data-offset="-100"
                aria-haspopup="true"
                aria-expanded="false"
                x-placement="bottom-end"
              >
                <span>{{ usuario?.nome | capitalize }}</span>
                <i class="fas fa-cog ml-1"></i>
              </button>
              <span class="toolti">Configurações</span>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a
                  (click)="navigateToSettings('forms')"
                  class="dropdown-item"
                  type="button"
                >
                  <i class="fas fa-clipboard-list"></i>
                  Formulários
                </a>
                <a
                  (click)="navigateToSettings('schedules')"
                  class="dropdown-item"
                  type="button"
                >
                  <i class="fas fa-user-clock"></i>
                  Configurações de turnos
                </a>
              </div>
            </div>
          </div>
          <div id="VerticalDivider"></div>
          <span id="btn-sair" class="cursor-pointer" (click)="logout()">
            Logout
            <i class="ml-1 fas fa-sign-out-alt"></i>
          </span>
        </section>
      </div>
    </div>
    <div class="flex margin-header mt-2 header-place">
      <span class="animate__animated animate__bounceInLeft text-muted">
        <div class="d-flex align-items-center">
          Você está em:

          <span id="local_atendimento-header" (click)="navigateTelaEscolhaAmbulatorio()"
            class="ml-2 text-success font-weight-bold cursor-pointer">
            {{
            stateHeaderService.ambulatorio?.nome | semInformacao | capitalize
            }}
          </span>
          <div id="VerticalDivider"></div>
          <span id="guiche-header" (click)="navigateTelaEscolhaAmbulatorio()"
            class="text-success font-weight-bold cursor-pointer">
            {{ stateHeaderService.guiche?.nome }}:
            {{ stateHeaderService.guiche?.guiche | semInformacao }}
          </span>
        </div>
      </span>

      <span class="text-muted ml-auto header-date">
        {{ hoje | dateFormat: "LLLL" | capitalize }}
      </span>
    </div>
  </div>
</div>
