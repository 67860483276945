import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imc',
})
export class ImcPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    if (value > 0) {
      if (value < 18.5) {
        return 'Abaixo do peso';
      } else if (value >= 18.5 && value <= 24.9) {
        return 'Peso normal';
      } else if (value >= 25.0 && value <= 29.9) {
        return 'Sobrepeso';
      } else if (value >= 30.0 && value <= 34.9) {
        return 'Obesidade I';
      } else if (value >= 35.0 && value <= 39.9) {
        return 'Obesidade II';
      } else if (value >= 40.0) {
        return 'Obesidade III';
      }

      return value;
    }
  }
}
