import { Component, Input, Output } from '@angular/core';
import { IComment } from '../../models/posts.model';
import { CommentService } from '../../services/comment/comment.service';
import { EventEmitter } from '@angular/core';
import { Toast } from '../../utils/toast/toast';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'schendule-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css'],
})
export class InputComponent {
  @Input() schenduling_id: string;
  @Input() quantity: number;

  comment: IComment = {
    id: '',
    message: '',
  };


  @Output() onSend: EventEmitter<IComment> = new EventEmitter();

  constructor(private service: CommentService, private toast: Toast) {  
    this.schenduling_id = '';
  }

  isRetry() {
    return this.service.retrying;
  }

  send(): void {
    if (this.comment.message) {
      this.service
        .create(this.comment, this.schenduling_id)
        .subscribe(
          (result: IComment) => {
            this.comment.message = '';
            this.onSend.emit(result);
            this.service.retrying = false;
            this.service.getAll(this.schenduling_id).subscribe(result=>this.quantity = result.length);
            this.toast.success({ title: '', message: 'Observação Enviada' });
          },
          (error: HttpErrorResponse) => {
            this.toast.error({ title: '', message: 'Observação não enviada' });
          }
        );
    }
  }

  onEnter(event: any) {
    event.preventDefault();
    this.send();
  }
}
