import { Email } from './../../models/email.model';
import { environment } from './../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { delay, retryWhen, scan } from 'rxjs/operators';
import { IRecipient, IAddress } from './../../models/recipient.model';

@Injectable({
  providedIn: 'root',
})
export class RecipientService {
  url: string;
  token: string;

  constructor(private readonly http: HttpClient) {
    this.url = environment.apiV3;
  }

  getFilter(
    filter: string,
    entrySurvey: string,
    size?: number,
    page?: number
  ): Observable<IRecipient[]> {
    return this.http
      .get<IRecipient[]>(
        `${this.url}/v3/clinic/recipients?key=${filter}&value=${entrySurvey}&size=${size}&page=${page}`,
        {
          headers: {
            authorization: `Bearer ${this.token}`,
          },
        }
      )
      .pipe(
        retryWhen((errors: any) =>
          errors.pipe(
            delay(3000),
            scan((count: number) => {
              if (count >= 3) {
                throw errors;
              } else {
                count++;
              }
              return count;
            }, 0)
          )
        )
      );
  }

  getSingle(cpf: string): Observable<IRecipient> {
    return this.http.get<IRecipient>(
      `${this.url}/v3/clinic/recipients/${cpf}`,
      {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      }
    );
  }

  create(data: IRecipient): Observable<IRecipient> {
    return this.http
      .post<IRecipient>(`${this.url}/v3/clinic/recipients`, data, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      })
      .pipe(
        retryWhen((errors: any) =>
          errors.pipe(
            delay(3000),
            scan((count: number) => {
              if (count >= 3) {
                throw errors;
              } else {
                count++;
              }
              return count;
            }, 0)
          )
        )
      );
  }

  getAdress(cep: string): Observable<IAddress> {
    return this.http.get<IAddress>(`${this.url}/v3/address/${cep}`).pipe(
      retryWhen((errors: any) =>
        errors.pipe(
          delay(1000),
          scan((count: number) => {
            if (count >= 3) {
              throw errors;
            } else {
              count++;
            }
            return count;
          }, 0)
        )
      )
    );
  }
}
