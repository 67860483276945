import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit {
  @Input() inputId: string;
  @Input() mask: string;
  @Input() label: string;
  @Input() sharedVar: string;
  @Output() sharedVarChange = new EventEmitter();

  constructor() { }

  ngOnInit(): void { }

  change(newValue) {
    this.sharedVar = newValue;
    this.sharedVarChange.emit(newValue);
  }
}
