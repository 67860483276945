import { CpfPipe } from './cpf/cpf.pipe';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CapitalizePipe } from './capitalize/capitalize.pipe';
import { DateFormatPipe } from './date-format/date-format.pipe';
import { IdadePipe } from './idade.pipe';
import { ImcPipe } from './imc/imc.pipe';
import { NomeCortadoPipe } from './nome-cortado/nome-cortado.pipe';
import { OnlyNumberPipe } from './only-number/only-number.pipe';
import { SemInformacaoPipe } from './sem-informacao/sem-informacao.pipe';
import { TelefonePipe } from './telefone/telefone.pipe';
import { TimePipe } from './time/time.pipe';
import { WeekdayPipe } from './weekday/weekday.pipe';
import { MinutesPipe } from './minutes/minutes.pipe';
import { FloorPipe } from './floor/floor.pipe';
import { FirstLastNamePipe } from './first-last-name/first-last-name.pipe';

@NgModule({
  declarations: [
    CapitalizePipe,
    DateFormatPipe,
    SemInformacaoPipe,
    NomeCortadoPipe,
    OnlyNumberPipe,
    ImcPipe,
    TelefonePipe,
    IdadePipe,
    TimePipe,
    WeekdayPipe,
    MinutesPipe,
    FloorPipe,
    CpfPipe,
    FirstLastNamePipe,
  ],
  exports: [
    CapitalizePipe,
    DateFormatPipe,
    SemInformacaoPipe,
    NomeCortadoPipe,
    OnlyNumberPipe,
    ImcPipe,
    TelefonePipe,
    IdadePipe,
    TimePipe,
    WeekdayPipe,
    MinutesPipe,
    FloorPipe,
    CpfPipe,
    FirstLastNamePipe
  ],
  imports: [CommonModule],
})
export class PipesModule {}
