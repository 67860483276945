import { Component, Input} from '@angular/core';
@Component({
  selector: 'schendule-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent {

  @Input() quantity : number | null;

  constructor() { 
    this.quantity = null;
  }
  
}
