import { Beneficiario } from './../../models/beneficiario';
import { Agendamento } from 'projects/clinicas-liv/src/app/shared/models/agendamento';
import { StateListaAgendamentoService } from 'projects/clinicas-liv/src/app/shared/services/state-lista-agendamento/state-lista-agendamento.service';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cards-menu',
  templateUrl: './cards-menu.component.html',
  styleUrls: ['./cards-menu.component.scss'],
})
export class CardsMenuComponent implements OnInit {
  @Input() textoMenu: string;
  @Input() urlSvg: string;
  @Input() path: string;

  constructor(
    private router: Router,
    private stateListaAgendamentoService: StateListaAgendamentoService
  ) {}

  ngOnInit(): void {}

  clearState() {
    this.stateListaAgendamentoService.paramsAgendamento = {} as Agendamento;
    this.stateListaAgendamentoService.paramsBeneficiario = {} as Beneficiario;
  }

  navigateCaminhoMenu() {
    this.router.navigate([`${this.path}`]);
    this.clearState();
  }
}
