import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';

@Injectable({
  providedIn: 'root',
})
export class ChangeLogService extends BaseService {
  getReleases() {
    return this.getWithoutBaseUrl(
      'https://api.pep.livsaude.com.br/v1/auth/versoes',
      { id: 4 }
    );
  }
}
