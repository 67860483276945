import { Component, Input } from '@angular/core';

@Component({
  selector: 'schendule-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent{

  @Input() comments: any[]=[];
  @Input() shimmers: any[]=[];

  constructor() { }
  
}
