import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Beneficiario } from 'projects/clinicas-liv/src/app/shared/models/beneficiario';

@Component({
  selector: 'app-simple-list',
  templateUrl: './simple-list.component.html',
  styleUrls: ['./simple-list.component.scss'],
})
export class SimpleListComponent implements OnChanges {
  @Input() dados: Beneficiario[] = [];
  @Input() loadingBeneficiarios: boolean = false;
  @Output() beneficiarioClicado = new EventEmitter<any>();
  @Output() beneficiarioCliqueDuplo = new EventEmitter<any>();
  public beneficiario = {} as Beneficiario;

  public pesquisa: string;
  public parametro: string;
  public indexRegistroClicado: number;

  constructor(private toastr: ToastrService) {}

  ngOnChanges(_: SimpleChanges): void {
    if (this.loadingBeneficiarios) {
      this.beneficiario = null;
      this.indexRegistroClicado = null;
    }
  }

  getLinhaClicada(item, index) {
    this.beneficiario = item;
    this.indexRegistroClicado = index;
  }

  // classes de situação do beneficiário
  getClassSituacao(situacao: string) {
    if (situacao === 'Ativo') {
      return 'mini-circle mini-circle-green';
    } else {
      return 'mini-circle mini-circle-red';
    }
  }

  prosseguirBeneficiario() {
    if (this.beneficiario?.nome) {
      this.beneficiarioClicado.emit(this.beneficiario);
    } else {
      this.toastr.info('Selecione um beneficiário para prosseguir!');
    }
  }

  prosseguirBeneficiarioDbl(item) {
    this.beneficiarioCliqueDuplo.emit(item);
  }
}
