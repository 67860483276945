import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';

@Injectable({
  providedIn: 'root',
})
export class GuicheService extends BaseService {
  getGuiches() {
    return this.get(`clinica/pontos-atendimento?tipo=G`);
  }
}
