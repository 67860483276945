<div class="locale">
  <ng-select
    [id]="inputId"
    class="ng-custom"
    [placeholder]="label"
    [(ngModel)]="registerSelected"

    [disabled]="disabled"
    (ngModelChange)="emitRegistroClicado(registerSelected)"
    (mouseenter)="setEnableOnChange()"
  >
    <ng-option
      *ngFor="let item of dados"
      [value]="item.value"
    >
      {{item.label}}
    </ng-option>
  </ng-select>
</div>
