import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
  styleUrls: ['./default-header.component.scss']
})
export class DefaultHeaderComponent implements OnInit {
  @Input() title: string;
  @Output() showModal: EventEmitter<any> = new EventEmitter();
  @Output() showModalGestante: EventEmitter<any> = new EventEmitter();
  @Input() n1_selected?: boolean;
  @Input() n2_selected?: boolean;
  @Input() go_back_path: string;
  @Input() has_add_button?: boolean;

  constructor(
    private readonly router: Router
  ) {
    this.n1_selected = null;
    this.n2_selected = null;
    this.has_add_button = null;
    this.title = '';
  }

  ngOnInit(): void {
  }
  openModalGestante(){    
    this.showModalGestante.emit(true)
  }

  openModal(){
    this.showModal.emit(true);
  }

  navigateToSchedule(){
    this.router.navigate([`settings/schedules`]);
  }

  navigateToServiceLocation(){
    this.router.navigate([`settings/service-location`]);
  }
}
