import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'projects/clinicas-liv/src/app/shared/shared.module';
import { CadastroGestanteComponent } from './cadastro-gestante/cadastro-gestante.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule, IConfig } from 'ngx-mask'
@NgModule({
  declarations: [
    CadastroGestanteComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    NgxMaskModule.forRoot()
  ],
  exports:[
    CadastroGestanteComponent
  ]
})
export class FormularioGestanteModule { }
