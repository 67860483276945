<div id="container-list-recipient" class="container">
  <app-default-header
    [go_back_path]="'inicio/ambulatorio'"
    [title]="'Buscar e cadastrar pessoa'"
    (showModal)="createRecipient($event)"
    (showModalGestante)="createPregnancy($event)"
    [has_add_button]="true"
  ></app-default-header>

  <section id="filters" class="filters">
    <div class="inputs">
      <div *ngIf="filter == 'name'">
        <div class="controler-search">
          <i class="fas fa-search"></i>
          <input
            type="text"
            autocomplete="off"
            placeholder="Digite o nome"
            [(ngModel)]="entrySurvey"
            (ngModelChange)="searchSingle()"
          />
        </div>
      </div>

      <div *ngIf="filter == 'cpf'">
        <div class="controler-search">
          <i class="fas fa-search"></i>
          <input
            type="text"
            autocomplete="off"
            placeholder="Digite o CPF"
            [mask]="mask"
            minlength="11"
            [(ngModel)]="entrySurvey"
            (ngModelChange)="searchSingle()"
            #cpf="ngModel"
            name="cpf"
          />
        </div>
        <div *ngIf="cpf.touched || cpf.dirty">
          <p *ngIf="cpf.errors?.minlength" class="alert">
            CPF digitado está incorreto
          </p>
        </div>
      </div>

      <div class="locale">
        <ng-select class="ng-custom" placeholder="Filtro" [(ngModel)]="filter">
          <ng-option [value]="'name'">Filtrar por Nome</ng-option>
          <ng-option [value]="'cpf'">Filtrar por CPF</ng-option>
        </ng-select>
      </div>
    </div>

    <section class="results">
      <section class="pages">
        <span id="numeration-pages">
          {{ page * size - size + 1 }} - {{ page * sizeText() }} de {{ total }}
        </span>
        <div class="tot">
          <button (click)="backPage()">
            <i class="fas fa-chevron-left"></i>
          </button>
          <span class="toolti">Anterior</span>
        </div>
        <div class="tot">
          <button (click)="nextPage()">
            <i class="fas fa-chevron-right"></i>
          </button>
          <span class="toolti">Próxima</span>
        </div>
      </section>
    </section>
  </section>
  <app-loading *ngIf="loading"></app-loading>
  <table *ngIf="recipients.length > 0">
    <thead>
      <tr>
        <th>Nome</th>
        <th>CPF</th>
        <th>Data de Nascimento</th>
        <th></th>
      </tr>
    </thead>

    <tbody>
      <tr class="tr-result" *ngFor="let recipient of recipients" (dblclick)="fetchRecipient(recipient.id)">
        <td>{{ recipient.name | firstLastName}}</td>
        <td>{{ recipient.cpf | semInformacao }}</td>
        <td>{{ recipient.birthdate | date: "shortDate" | semInformacao}}</td>
        <td class="d-flex justify-content-end">
          <div class="tot mr-2">
            <button *ngIf="recipient.pregnancies.length>0" class="btn-edit-pregnancy" (click)="fetchRecipientPregnancy(recipient.id)" (click)="closeModalPregnancy($event)">
              <i class="fas fa-baby"></i>


            </button>

            <span class="toolti">Editar gestação</span>
            <button *ngIf="recipient.pregnancies.length==0  && recipient.gender == 'F'" class="btn-edit-pregnancy" (click)="fetchRecipientPregnancy(recipient.id)" (click)="closeModalPregnancy($event)">
              <span class="material-icons-outlined">
                pregnant_woman
                </span>
            </button>

            <span *ngIf="recipient.pregnancies.length==0 && recipient.gender == 'F'" class="toolti">Cadastrar Gestação</span>
          </div>



          <div class="tot mr-2">
            <button
              (click)="fetchRecipient(recipient.id)"
            >
              <i class="fas fa-user-edit"></i>
            </button>

            <span class="toolti">Editar</span>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="not-provider" *ngIf="recipients.length == 0 && loading == false">
    <p>Nenhum beneficiário encontrado</p>
  </div>
</div>
<div *ngIf="showModal">
  <app-record
    (closeModal)="closeModal($event)"
    [data]="dataRecipient"
    [editTwo]="edit"
  ></app-record>
</div>
<app-cadastro-gestante
(closeModal)="closeModalPregnancy($event)"
[recipient]="dataRecipient"
*ngIf="showPregnancy"
>
</app-cadastro-gestante>


