import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'telefone',
})
export class TelefonePipe implements PipeTransform {
  transform(fone: any, args?: any): any {
    let numeroAjustado;

    if (fone) {
      const informadoComMascara = fone.length > 11;
      const informadoComDDD9 = fone.length === 11;
      const informadoComDDDSem9 = fone.length === 10;
      const informadoSemDDD9 = fone.length === 9;
      const informadoSemDDD = fone.length === 8;

      if (informadoComDDD9) {
        const parte1 = fone.slice(0, 2);
        const parte2 = fone.slice(2, 7);
        const parte3 = fone.slice(7, 11);
        numeroAjustado = `(${parte1}) ${parte2}-${parte3}`;
      } else if (informadoComDDDSem9) {
        const parte1 = fone.slice(0, 2);
        const parte2 = fone.slice(2, 6);
        const parte3 = fone.slice(6, 10);
        numeroAjustado = `(${parte1}) ${parte2}-${parte3}`;
      } else if (informadoSemDDD9) {
        const parte1 = fone.slice(0, 5);
        const parte2 = fone.slice(5, 9);
        numeroAjustado = `${parte1}-${parte2}`;
      } else if (informadoSemDDD) {
        const parte1 = fone.slice(0, 4);
        const parte2 = fone.slice(4, 8);
        numeroAjustado = `${parte1}-${parte2}`;
      } else if (informadoComMascara) {
        return fone;
      }
    } else {
      return 'Contato não informado';
    }

    return numeroAjustado;
  }
}
