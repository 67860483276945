import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth/auth.guard';
import { LogoutGuard } from './core/guards/logout/logout.guard';
import { LoginComponent } from './pages/login/login.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    pathMatch: 'full',
    canActivate: [LogoutGuard],
  },
  {
    path: 'inicio',
    loadChildren: () =>
      import('./modules/layout-default/layout-default.module').then(
        (m) => m.LayoutDefaultModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
  },
  {
    path: 'recipient',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/recipient/recipient.module').then(
        (m) => m.RecipientModule
      ),
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
