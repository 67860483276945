import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '../../pipes/pipes.module';
import { ModalChangelogComponent } from './modal-changelog.component';

@NgModule({
  declarations: [ModalChangelogComponent],
  exports: [ModalChangelogComponent],
  imports: [CommonModule, PipesModule],
})
export class ModalChangelogModule {}
