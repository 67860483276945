import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";

export interface ToastModel {
  title: string
  message: string
}

@Injectable({
  providedIn: 'root',
})
export class Toast {

  constructor(private toastr: ToastrService) {}

  error(model: ToastModel) {
    return this.toastr.show(model.title, model.message, {closeButton:true, progressBar: true, positionClass: "toast-bottom-left"}, 'toast-error');
  }

  success(model: ToastModel) {
    return this.toastr.show(model.title, model.message, {closeButton:true, progressBar: true, positionClass: "toast-bottom-left"}, 'toast-success');
  }

  warning(model: ToastModel) {
    return this.toastr.show(model.title, model.message, {closeButton:true, progressBar: true}, 'toast-warning');
  }

}