import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '../../pipes/pipes.module';
import { TagsAtendimentoComponent } from './tags-atendimento.component';

@NgModule({
  declarations: [TagsAtendimentoComponent],
  exports: [TagsAtendimentoComponent],
  imports: [CommonModule, PipesModule],
})
export class TagsAtendimentoModule {}
