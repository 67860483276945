import { Painel } from './../../models/painel';
import { Injectable } from '@angular/core';
import { Ambulatorio } from '../../models/ambulatorio';
import { StorageService } from '../storage/storage.service';

interface Guiche {
  id: string;
  guiche: string;
  nome: string;
}

@Injectable({
  providedIn: 'root',
})
export class StateHeaderService {
  public ambulatorio = {} as Ambulatorio;
  public ambulatorios: Ambulatorio[] = [];
  public guiche = {} as Guiche;
  public guiches: Guiche[] = [];
  public painel = {} as Painel;
  public paineis: Painel[] = [];

  constructor(private storageService: StorageService) {}

  getAmbulatorio() {
    this.ambulatorios = this.storageService.getAmbulatorios();
    let ambulatorio = this.storageService.getAmbulatorio();

    let ambulatorioFiltrado = this.ambulatorios.find(
      (element) => element.id == ambulatorio
    );

    if (ambulatorio) {
      this.ambulatorio = ambulatorioFiltrado;
    } else {
      this.ambulatorio = this.ambulatorios[0];
      this.storageService.setAmbulatorio(this.ambulatorio.id);
    }
  }

  getGuiche() {
    this.guiches = this.storageService.getGuiches();
    let guiche = this.storageService.getGuiche();

    let guicheFiltrado = this.guiches?.find((element) => element.id == guiche);

    if (guiche) {
      this.guiche = guicheFiltrado;
    } else {
      if (this.guiches) {
        this.guiche = this.guiches[0];
        this.storageService.setGuiche(this.guiche.id);
      }
    }
  }

  getPainel() {
    this.paineis = this.storageService.getPaineis();
    let painel = this.storageService.getPainel();

    let painelFiltrado = this.paineis?.find((element) => element.id == painel);

    if (painel) {
      this.painel = painelFiltrado;
    } else {
      if (this.paineis) {
        this.painel = this.paineis[0];
        this.storageService.setPainel(this.painel.id);
      }
    }
  }
}
