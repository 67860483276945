import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TagRiscoComponent } from './tag-risco.component';

@NgModule({
  declarations: [TagRiscoComponent],
  exports: [TagRiscoComponent],
  imports: [CommonModule],
})
export class TagRiscoModule {}
