import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nomeCortado',
})
export class NomeCortadoPipe implements PipeTransform {
  transform(value: string, ...args: any[]): string {
    const temp = value?.split(' ');
    return (temp[0] + ' ' + temp[temp.length - 1]).toUpperCase();
  }
}
