import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss'],
})
export class TextFieldComponent {
  @Input() inputId: string;
  @Input() mask: string;
  @Input() label: string;
  @Input() sharedVar: string;
  @Output() sharedVarChange = new EventEmitter();

  constructor() {}

  change(newValue) {
    this.sharedVar = newValue;
    this.sharedVarChange.emit(newValue);
  }
}
