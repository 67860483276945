<div class="modal-backdrop-introdution">
  <div id="modal-form-introdution">
    <div>
      <h6>{{ title }}</h6>

      <div id="close-container">
        <button>
          <span class="material-icons-outlined" (click)="selectNo()">close</span>
        </button>
      </div>
    </div>

    <p>
      {{ description }}
    </p>

    <div class="button-modal-introdution">
      <button (click)="selectYes()">Sim</button>
      <button (click)="selectNo()">Não</button>
    </div>
  </div>
</div>
