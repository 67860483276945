import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/clinicas-liv/src/environments/environment';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { Beneficiario } from '../../../shared/models/beneficiario';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiUrl;
  }

  // memory leak loiane Http
  memoryLeak(request) {
    return request.pipe(
      tap(() => {}),
      take(1)
    );
  }

  sanitizeQueryString(query: any) {
    let keys = Object.keys(query);

    const params = {};
    keys.map((key) => {
      if (query[key]) {
        params[key] = query[key];
      }
    });
    return params;
  }

  get<T = any>(uri: string, query?: any) {
    let params = this.sanitizeQueryString(query || {});

    return this.memoryLeak(
      this.http.get<T>(`${this.baseUrl}${uri}`, {
        params: params,
      })
    ) as Observable<T>;
  }

  get_v3<T = any>(uri: string, query?: any) {
    const url = this.baseUrl.replace('v2/', '');
    let params = this.sanitizeQueryString(query || {});

    return this.memoryLeak(
      this.http.get<T>(`${url}${uri}`, {
        params: params,
      })
    ) as Observable<T>;
  }

  get_local<T = any>(uri: string, query?: any) {
    const url = 'https://api.tst.pep.livsaude.com.br/v3/';
    let params = this.sanitizeQueryString(query || {});

    return this.memoryLeak(
      this.http.get<T>(`${url}${uri}`, {
        params: params,
      })
    ) as Observable<T>;
  }

  getImprimir(uri: string) {
    return this.http.post(
      `${this.baseUrl}${uri}`,
      {},
      { responseType: 'blob' }
    );
  }

  getWithoutBaseUrl(uri: string, query?: any) {
    let params = this.sanitizeQueryString(query || {});

    return this.memoryLeak(
      this.http.get<any[]>(`${uri}`, {
        params: params,
      })
    );
  }

  post_v3(uri: string, data: any) {
    const url = this.baseUrl.replace('v2/', '');
    return this.memoryLeak(this.http.post<any[]>(`${url}${uri}`, data));
  }

  post(uri: string, data: any) {
    return this.memoryLeak(
      this.http.post<any[]>(`${this.baseUrl}${uri}`, data)
    );
  }

  patch(uri: string, data: any) {
    return this.memoryLeak(
      this.http.patch<any[]>(`${this.baseUrl}${uri}`, data)
    );
  }

  put(uri: string, data: any) {
    return this.memoryLeak(this.http.put<any[]>(`${this.baseUrl}${uri}`, data));
  }

  delete(uri: string) {}
}
