import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'onlyNumber',
})
export class OnlyNumberPipe implements PipeTransform {
  transform(value: string, ...args: any[]): string {
    if (value) {
      let stringNumber = value;
      return stringNumber.replace(/\D/g, '');
    }

    return '';
  }
}
