import { Component, Input, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-tag-risco',
  templateUrl: './tag-risco.component.html',
  styleUrls: ['./tag-risco.component.scss'],
})
export class TagRiscoComponent implements OnInit {
  @Input() idRisco: number;

  tagsRisco: any = [
    { id: 1, titulo: 'Emergência', color: '#CC0000' },
    { id: 2, titulo: 'Muito Urgente', color: '#cc9600' },
    { id: 3, titulo: 'Urgente', color: '#CCBE00' },
    { id: 4, titulo: 'Pouco Urgente', color: '#00CC30' },
    { id: 5, titulo: 'Não urgente', color: '#009CCC' },
  ];

  constructor() {}

  ngOnInit(): void {
    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }

  getRisco() {
    let response = this.tagsRisco.filter((item) => item.id === this.idRisco);
    return {
      cor: response[0]?.color,
      tooltip: response[0]?.titulo,
    };
  }
}
