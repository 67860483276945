import { Injectable } from '@angular/core';
import { Agendamento } from '../../models/agendamento';
import { FiltroAgendamento } from '../../models/filtro-agendamento';
import { TipoSenha } from '../../models/tipo-senha';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private localStorage: LocalStorageService) {}

  getAmbulatorios(): any {
    return this.localStorage.getList('ambulatorios');
  }

  getPrestador(): any {
    return this.localStorage.getObject('prestador');
  }

  getUsuario(): any {
    return this.localStorage.getObject('usuario');
  }

  setAmbulatorios(aux: any) {
    let ambulatorios = aux.map((item) => {
      return {
        id: item.id,
        nome: item.nome,
        // endereco: item.endereco,
        // cidade: item.ccidapres,
        // cep: item.ccep_pres.replace('-', ''),
        // uf: item.cestapres,
        // telefone: item.cfonepres,
        // em_atendimento: item.atenambu,
        // primeiro: item.primeiro,
        // ultimo: item.ultimo,
      };
    });
    this.localStorage.setList('ambulatorios', ambulatorios);
  }

  getAmbulatorio(): any {
    return this.localStorage.getString('ambulatorio');
  }

  setAmbulatorio(aux: any) {
    this.localStorage.setString('ambulatorio', aux);
  }

  setGuiche(aux: any) {
    this.localStorage.setString('guiche', aux);
  }

  setPainel(aux: any) {
    this.localStorage.setString('painel', aux);
  }

  getGuiches(): any {
    return this.localStorage.getList('guiches');
  }

  getPaineis(): any {
    return this.localStorage.getList('paineis');
  }

  setGuiches(aux: any) {
    let guiches = aux.map((item) => {
      return {
        id: item.id,
        guiche: item.numero,
        nome: item.nome,
      };
    });
    this.localStorage.setList('guiches', guiches);
  }

  setPaineis(aux: any) {
    let paineis = aux.map((item) => {
      return {
        id: item.id,
        nome: item.nome,
      };
    });
    this.localStorage.setList('paineis', paineis);
  }

  getComplementos(): any {
    return this.localStorage.getList('complementos');
  }

  setComplementos(aux): any {
    return this.localStorage.setList('complementos', aux);
  }

  getGuiche() {
    return this.localStorage.getString('guiche');
  }

  getPainel() {
    return this.localStorage.getString('painel');
  }

  getToken() {
    return this.localStorage.getString('token');
  }

  setToken(token: string) {
    this.localStorage.setString('token', token);
  }

  setPrestador(prestador_id: any) {
    let prestador = {
      id: prestador_id,
      // nome: data.cnomepres,
      // aguardando_atend: data.aguardando_atend,
      // ultimo: data.ultimo,
      // primeiro: data.primeiro,
      // conselho: {
      //   sigla: data.cconspres,
      //   numero: data.ncrm_pres,
      //   uf: data.cestapres,
      // },
    };

    this.localStorage.setObject('prestador', prestador);
  }

  setUsuario(data: any) {
    let usuario = {
      id: data.id,
      nome: data.nome,
      usuario: data.usuario,
      // roles: data.role,
    };

    let aux = usuario?.nome?.split(' ');
    if (aux.length >= 2) {
      let nome_social = aux[0] + ' ' + aux[aux.length - 1];
      usuario = { ...usuario, nome: nome_social };
    }

    this.localStorage.setObject('usuario', usuario);
  }

  getDadosAgendamento() {
    return this.localStorage.getObjectSession('dadosAgendamento');
  }

  setDadosAgendamento(agendamento: Agendamento) {
    this.localStorage.setObjectSession('dadosAgendamento', agendamento);
  }

  getFiltroAgendamento() {
    return this.localStorage.getObject('filtro-dropdown-agenda');
  }

  setFiltroAgendamento(filtro: FiltroAgendamento) {
    this.localStorage.setObject('filtro-dropdown-agenda', filtro);
  }

  removeFiltroAgendamento() {
    localStorage.removeItem('filtro-dropdown-agenda');
  }

  clear() {
    this.localStorage.clear();
  }

  processaLogin(data: any) {
    this.setToken(data.access_token);
    this.setAmbulatorios(data.ambulatorios);
    this.setPrestador(data.prestador_id);
    this.setUsuario(data);
  }

  getTiposSenhas() {
    return this.localStorage.getList('tipos-senhas');
  }

  setTiposSenhas(tipos: TipoSenha[]) {
    this.localStorage.setList('tipos-senhas', tipos);
  }
}
