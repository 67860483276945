import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstLastName'
})
export class FirstLastNamePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    if(!value) {
      return '';
    }
    const splitted = value.split(' ');
    const [firstname] = splitted;
    const lastname = splitted[splitted.length - 1];
    const words = [];
    if(splitted.length === 1) {
      return value;
    }
    splitted.forEach((w) => {
      words.push([firstname, lastname].includes(w) ? w : w[0]);
    })
    return words.join(' ');
  }

}
