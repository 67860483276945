import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BotaoVoltarComponent } from './components/botao-voltar/botao-voltar.component';
import { CardsMenuComponent } from './components/cards-menu/cards-menu.component';
import { BadgeComponent } from './components/badge/badge.component';
import { CommentsComponent } from './components/comments/comments.component';
import { InputComponent } from './components/input/input.component';
import { CommentsWrapperComponent } from './components/comments-wrapper/comments-wrapper.component';
import { CloseComponent } from './components/close/close.component';
import { NetworkStatusComponent } from './components/network-status/network-status.component';
import { SchenduleLoadingComponent } from './components/loading-obs/loading.component';
import { CommentsChatComponent } from './components/comments-chat/comments-chat.component';
import { TagRiscoModule } from './components/tag-risco/tag-risco.module';
import { TagsAtendimentoModule } from './components/tags-atendimento/tags-atendimento.module';
import { TextFieldComponent } from './components/text-field/text-field.component';
import { OrderModule } from 'ngx-order-pipe';
import { NgxMaskModule } from 'ngx-mask';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SimpleListComponent } from './components/simple-list/simple-list.component';
import { SelectComponent } from './components/select/select.component';
import { DefaultHeaderComponent } from './components/default-header/default-header.component';
import { LoadingLivComponent } from './components/loading-liv/loading-liv.component';
import { ModalDefaultComponent } from './components/modal-default/modal-default.component';

@NgModule({
  declarations: [
    TextFieldComponent,
    CardsMenuComponent,
    BotaoVoltarComponent,
    BadgeComponent,
    CommentsComponent,
    InputComponent,
    CommentsWrapperComponent,
    CloseComponent,
    SchenduleLoadingComponent,
    CommentsChatComponent,
    NetworkStatusComponent,
    SearchInputComponent,
    SimpleListComponent,
    SelectComponent,
    DefaultHeaderComponent,
    LoadingLivComponent,
    ModalDefaultComponent
  ],
  exports: [
    TextFieldComponent,
    CardsMenuComponent,
    BotaoVoltarComponent,
    BadgeComponent,
    CommentsComponent,
    InputComponent,
    CommentsWrapperComponent,
    CloseComponent,
    SchenduleLoadingComponent,
    CommentsChatComponent,
    NetworkStatusComponent,
    SearchInputComponent,
    SimpleListComponent,
    SelectComponent,
    DefaultHeaderComponent,
    LoadingLivComponent,
    ModalDefaultComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TagRiscoModule,
    TagsAtendimentoModule,
    NgxMaskModule.forChild(),
    OrderModule,
    NgSelectModule,
    OrderModule
  ],
})
export class SharedModule { }
