<!-- Modal -->
<div
  class="modal fade"
  id="modal-changelog"
  tabindex="-1"
  role="dialog"
  aria-labelledby="modalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-md" role="document">
    <div class="modal-content">
      <div class="modal-body content-body">
        <div class="d-flex align-items-center mb-1">
          <img
            src="assets/svg/confete.svg"
            alt="confete icon"
            width="25"
            class="mr-1"
          />
          <h3 class="text-primary font-weight-bold">Novidades</h3>
        </div>

        <div class="pb-16">
          <span class="font-weight-bold text-muted sub-title">
            Confira as novidades que temos para você!
          </span>
          <a
            href="https://livsaude.atlassian.net/wiki/spaces/CLIV/pages/399835168/Cl+nicas+Manual+do+Usu+rio"
            target="_blank"
          >
            Navegar para manual
          </a>
        </div>

        <section
          class="scroll-section animation-fade-500"
          *ngIf="!loading; else loadingSpinner"
        >
          <div *ngFor="let item of releases" class="mb-5">
            <span class="d-flex font-weight-bold text-primary">
              {{ item.data_publicacao | dateFormat: "LLLL" | capitalize }}
            </span>
            <span class="text-muted section-info">
              Versão {{ formatVersion(item.versao) }}
            </span>

            <ul class="list-custom">
              <li *ngFor="let item of item.descricao.split('|')" class="mb-2">
                <span class="text-lg">
                  {{ item }}
                </span>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingSpinner>
  <div class="my-3">
    <div class="d-flex align-items-center justify-content-center mb-3">
      <span class="text-muted"> Carregando o histórico de novidades </span>
    </div>

    <div class="d-flex align-items-center justify-content-center">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</ng-template>
