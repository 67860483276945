import { Directive } from '@angular/core';
import { Validator, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[directiveCep]',
})
export class CepDirective implements Validator {
  validate(control: AbstractControl): { [key: string]: any } | null {
    return control.value === '^d{5}-d{3}$' ? { 'defaultSelected': true } : null;
  }
}
