import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PathService } from '../../../core/services/path/path.service';

@Component({
  selector: 'app-botao-voltar',
  templateUrl: './botao-voltar.component.html',
  styleUrls: ['./botao-voltar.component.scss'],
})
export class BotaoVoltarComponent implements OnInit {
  @Input() path: string;

  constructor(private pathService: PathService, private router: Router) {}

  ngOnInit(): void {}

  navigateBack() {
    if (this.path) {
      this.router.navigate([this.path]);
    } else {
      this.pathService.navigateBack();
    }
  }
}
