<div class="maincontent">
  <div class="contentsecond">
    <div class="contentmodal">
      <nav>
        <div>
          <h3>Cadastrar gestação</h3>
          <!-- <label *ngIf="!formGestante.valid" class="required-div1">Campos com * são obrigatórios</label>
                     -->
          <hr class="blue-line" />
        </div>
        <button class="button-nav" (click)="closePregnancy()">x</button>
      </nav>

      <form [formGroup]="formGestante">
        <div class="input-layout">
          <div class="first-column">
            <div class="column-flex">
              <label>Nome</label>
              <input
                class="readnow"
                type="text"
                readonly
                [value]="recipient?.name"
              />
            </div>
            <div class="column-flex leftfirst">
              <label>Idade</label>
              <input
                [ngModel]="recipient?.pregnancies?.age"
                type="text"
                formControlName="age"
                readonly
                class="readnow"
              />
            </div>
          </div>

          <div class="second-column">
            <div class="column-flex">
              <label
                >Início da gestação <label class="required-div">*</label></label
              >
              <input
                [ngModel]="recipient?.pregnancies?.early_pregnancy"
                [ngClass]="{
                  'required-inputdata':
                    formGestante.controls.early_pregnancy.errors &&
                    formGestante.controls.early_pregnancy.touched,
                  'valid-inputdata': formGestante.controls.early_pregnancy.valid
                }"
                type="date"
                formControlName="early_pregnancy"
              />
            </div>


            <div class="column-flex">
              <label>Data provável de parto</label>
              <input
              [ngClass]="{
                'required-inputdata':
                  formGestante.controls.dpp.errors &&
                  formGestante.controls.dpp.touched &&
                  formGestante.controls.dpp.invalid,

                'valid-inputdata': formGestante.controls.dpp.valid
              }"
                formControlName="dpp"
                type="date"
                name=""
                id=""
                [ngModel]="recipient?.pregnancies?.dpp"
                required
              />
            </div>
            <div class="column-flex">
              <label>Risco<label class="required-div">*</label></label>

              <!-- <select
                [ngModel]="recipient?.pregnancies?.risk"
                [ngClass]="{
                  'required-select':
                    formGestante.controls.risk.errors &&
                    formGestante.controls.risk.touched,
                  'valid-select': formGestante.controls.risk.valid
                }"
                formControlName="risk"
              >
                <option [ngValue]="true">Sim</option>
                <option [ngValue]="false">Não</option>
              </select> -->
              <ng-select

            [ngModel]="recipient?.pregnancies?.risk"
            name="gender"
            class="ng-select"
            formControlName="risk"
            required
          >
            <ng-option class="ngtest" *ngFor="let risk of risks" [value]="risk.value">{{
              risk.name
            }}</ng-option>
          </ng-select>
            </div>
          </div>
          <div class="third-column">
            <div class="column-flex">
              <label
                >Diabetes Mellitus <label class="required-div">*</label></label
              >
              <!-- <select
                name="select"
                [ngClass]="{
                  'required-select':
                    formGestante.controls.diabetes_militus.errors &&
                    formGestante.controls.diabetes_militus.touched,
                  'valid-select': formGestante.controls.diabetes_militus.valid
                }"
                formControlName="diabetes_militus"
                [ngModel]="recipient?.pregnancies?.diabetes_militus"
              >
                <option value="N">Não possui</option>
                <option value="Type 1">Tipo 1</option>
                <option value="Type 2">Tipo 2</option>
              </select> -->
              <ng-select

            [ngModel]="recipient?.pregnancies?.diabetes_militus"
            name="gender"
            class="ng-select"
            formControlName="diabetes_militus"
            required
          >
            <ng-option  *ngFor="let diabetes of diabetes" [value]="diabetes.value">{{
              diabetes.name
            }}</ng-option>
          </ng-select>
            </div>
            <div class="column-flex">
              <label
                >Hipertensão A. Sistêmica<label class="required-div"
                  ></label
                ></label
              >

              <!-- [ngClass]="{
                'required-input':
                  formGestante.controls.hypertension.errors &&
                  formGestante.controls.hypertension.touched &&
                  formGestante.controls.hypertension.invalid,

                'valid-input': formGestante.controls.hypertension.valid
              }" -->
              <input

                [ngModel]="recipient?.pregnancies?.hypertension"

                type="text"
                name=""
                id=""
                formControlName="hypertension"
              />
            </div>
            <div class="column-flex">
              <label>Índice Apgar</label>
              <ng-select
              [ngModel]="recipient?.pregnancies?.apgar"
              name="apgarr"
              class="ng-select"
              formControlName="apgar"

            >
              <ng-option class="ngtest" *ngFor="let indapgar of indapgar" [value]="indapgar.value">{{
                indapgar.name
              }}</ng-option>
            </ng-select>
            </div>
          </div>
          <div class="fourth-column">
            <div class="column-flex">
              <label>Peso do RN</label>
              <input
                class="input-kg"
                type="number"
                name=""
                id=""
                formControlName="rn_weight"
                [ngModel]="recipient?.pregnancies?.rn_weight"
              />
            </div>

            <div class="column-flex">

              <label>Data do parto</label>
              <input
                [ngModel]="recipient?.pregnancies?.childbirth_date"
                type="date"
                name=""
                id=""
                formControlName="childbirth_date"
              />
            </div>

            <div class="column-flex">
              <label>Tipo de parto realizado </label>
              <!-- <select
                [ngModel]="recipient?.pregnancies?.childbirth_type"
                name="select"
                formControlName="childbirth_type"
              >
                <option value="Cesárea">Cesárea</option>
                <option value="Normal">Normal</option>
              </select> -->
              <ng-select
            [ngModel]="recipient?.pregnancies?.childbirth_type"
            name="gender"
            class="ng-select"
            formControlName="childbirth_type"
          >
            <ng-option  *ngFor="let part of parts" [value]="part.value">{{
              part.name
            }}</ng-option>
          </ng-select>

            </div>

          </div>
          <!-- <button  (click)="createPregnancy()"
                class="button-prosseguir"></button> -->

          Observações :
          <textarea
            [ngModel]="recipient?.pregnancies?.observations"
            formControlName="observations"
            rows="4"
          ></textarea>
          <div *ngIf="!formGestante.valid" class="required-prosseguir">
            Preencha os campos obrigatórios
          </div>
          <div class="div-buttonsflex">
            <!-- <button (click)="deletePregnancy(pregnancy)" class="button-prosseguir">Deletar</button> -->
            <!-- <button class="button-prosseguir" (click)="receberInfos()">Teste</button> -->

            <button
              *ngIf="recipient.pregnancies == null"
              type="submit"
              class="button-prosseguir"
              [disabled]="!formGestante.valid"
              (click)="createPregnancy()"

            >
              Salvar
            </button>
            <button
              *ngIf="recipient.pregnancies != null"
            class="button-prosseguir"
              [disabled]="!formGestante.valid"
              (click)="updatePregnancy()"
              type="submit"
            >
              Atualizar
            </button>
            <!-- (click)="createPregnancy()"  -->
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
