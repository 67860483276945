import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { ModalChangelogModule } from 'projects/clinicas-liv/src/app/shared/components/modal-changelog/modal-changelog.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './core/guards/auth/auth.guard';
import { LogoutGuard } from './core/guards/logout/logout.guard';
import { Interceptor } from './core/interceptors/interceptor.module';
import { LoginComponent } from './pages/login/login.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { SharedModule } from './shared/shared.module';
import { NgxMaskModule } from 'ngx-mask';
import { RecipientModule } from './modules/recipient/recipient.module';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NotFoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    Interceptor,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    ModalChangelogModule,
    NgxMaskModule.forRoot(),
    RecipientModule
  ],
  providers: [AuthGuard, LogoutGuard],
  bootstrap: [AppComponent]
})
export class AppModule {}
