import { Subject } from 'rxjs';
import { RecipientService } from '../../services/recipient/recipient.service';
import { Component, OnInit } from '@angular/core';
import { debounceTime, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Toast } from 'projects/clinicas-liv/src/app/shared/utils/toast/toast';
import { IRecipient } from '../../models/recipient.model';

@Component({
  selector: 'app-recipient-list',
  templateUrl: './recipient-list.component.html',
  styleUrls: ['./recipient-list.component.scss'],
})
export class RecipientListComponent implements OnInit {
  subject = new Subject();
  size: number;
  total: number;
  filter: string;
  mask: string;
  entrySurvey: string;
  page: number;
  lastPage: number;
  loading: boolean;
  showModal: boolean;
  showPregnancy:boolean;
  showModalEdit: boolean;
  edit: boolean;
  recipients: IRecipient[];
  dataRecipient: IRecipient;

  constructor(
    private readonly service: RecipientService,
    private toast: Toast
  ) {
    this.size = 15;
    this.page = 1;
    this.total = 0;
    this.recipients = [];
    this.filter = 'name';
    this.mask = '000.000.000-00';
    this.loading = false;
    this.showModal = false;
    this.showPregnancy = false;
    this.edit = false;
  }

  ngOnInit() {
    this.service
      .getFilter(this.filter, '', this.size, this.page)
      .subscribe((result: any) => {
        this.loading = false;
        this.recipients = result.rows;
        this.total = result.total;
        this.size = result.size;
        this.page = result.page;
      });
    this.subject
      .pipe(
        debounceTime(800),
        map((search: string) =>
          this.service.getFilter(this.filter, search, this.size, 1)
        )
      )
      .subscribe((search) => {
        if (this.entrySurvey.length > 0) {
          this.page = 1;
          search.subscribe(
            (itens: any) => {
              this.loading = false;
              this.recipients = itens.rows;
              this.total = itens.total;
              this.size = itens.size;
              this.page = itens.page;
              this.lastPage = Math.ceil(this.total / this.size);
              console.table(itens.rows)
            },
            (error: HttpErrorResponse) => {
              this.toast.error({
                title: '',
                message: 'Erro ao carregar as informações!',
              });
            }
          );
        } else {
          this.loading = false;
        }
      });

  }

  searchSingle() {
    this.loading = true;
    this.recipients = [];
    this.subject.next(this.entrySurvey);
  }

  fetchRecipient(CpfRecipient: string) {
    this.service.getSingle(CpfRecipient).subscribe((result) => {
      this.dataRecipient = result;
      this.showModal = true;
    });
  }

  fetchRecipientPregnancy(CpfRecipient: string) {
    this.service.getSingle(CpfRecipient).subscribe((result) => {
      this.dataRecipient = result;
    });
  }

  backPage() {
    if (this.page > 1) {
      this.recipients = [];
      this.loading = true;
      this.page--;
      this.fetchPage();
    }
  }

  nextPage() {
    if (this.page < this.lastPage && this.filter && this.entrySurvey) {
      this.recipients = [];
      this.loading = true;
      this.page++;
      this.fetchPage();
    } else {
      this.loading = false;
    }
  }

  fetchPage() {
    this.service
      .getFilter(this.filter, this.entrySurvey, this.size, this.page)
      .subscribe((result: any) => {
        this.loading = false;
        this.recipients = result.rows;
        this.total = result.total;
        this.size = result.size;
        this.page = result.page;
      });
  }

  closeModal($event: any) {
    this.showModal = $event;
    this.dataRecipient = undefined;
  }
  closeModalPregnancy($event: any) {
    this.showPregnancy = $event;
  }

  createRecipient($event: boolean) {
    this.showModal = $event;
    this.edit = false;
  }
  createPregnancy($event: boolean) {
    this.showPregnancy = $event;
  }

  sizeText() {
    if (this.total < this.size) {
      return this.total;
    }
    return this.size;
  }
}
