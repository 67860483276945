import { Component, OnInit } from '@angular/core';
import { NetworkStatusAngularService } from 'network-status-angular';

@Component({
  selector: 'schendule-network-status',
  templateUrl: './network-status.component.html',
  styleUrls: ['./network-status.component.css']
})
export class NetworkStatusComponent implements OnInit {

  connected: boolean | null;
  title: string;
  description: string;
  variant: string;
  icon: string;

  constructor(private readonly network: NetworkStatusAngularService) {
    this.connected = null;
    this.title = '';
    this.description = '';
    this.variant = '';
    this.icon = '';
  }

  ngOnInit(): void {
    this.network.status.subscribe((status) => {
      this.handleFill(status);
    });
  }

  onConnected() {
    this.title = 'Conectado novamente';
    this.description = 'A conexão com a rede foi reestabelecida.';
    this.variant = 'success';
    this.icon = 'cloud';
    const textarea = document.querySelector("#textarea");
    const buttonSend = document.querySelector("#button");
    buttonSend?.removeAttribute("disabled");
    textarea?.removeAttribute("disabled");
    setTimeout(() => {
      this.variant = '';
    }, 3000);
  }

  onDisconnected() {
    this.title = 'Você está offline';
    this.description =
      'Verifique sua conexão para continuar navegando normalmente.';
    this.variant = 'error';
    this.icon = 'cloud_off';
    const buttonSend = document.querySelector("#button");
    const textarea = document.querySelector("#textarea");
    buttonSend?.setAttribute("disabled","disabled");
    textarea?.setAttribute("disabled", "disabled");
  }

  handleFill(status: boolean) {
    this.connected = status;
    if (status) {
      this.onConnected();
    } else {
      this.onDisconnected();
    }
  }

}
