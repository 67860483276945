import { Injectable } from '@angular/core';
import { Agendamento } from '../../../shared/models/agendamento';
import { FinalizarAgendamento } from '../../../shared/models/finalizar-agendamento';
import { ParamsHistoricoAgendamento } from '../../../shared/models/paramsHistoricoAgendamento';
import { BaseService } from '../base/base.service';

import { IAcessoTeleliv } from './interfaces/acesso.teleliv.interface';

@Injectable({
  providedIn: 'root',
})
export class AgendamentoService extends BaseService {
  getDatasDisponiveis(parametros: Agendamento) {
    let query = {
      especialidade_id: parametros.especialidade?.id,
      unidade_id: parametros.ambulatorio?.id,
      prestador_id: parametros.prestador?.id,
    };

    return this.get(`agendamento/datas`, query);
  }

  getDatasGerais(
    especialidade_id: string,
    unidade_id: string,
    prestador_id: string
  ) {
    let query = {
      especialidade_id: especialidade_id,
      unidade_id: unidade_id,
      prestador_id: prestador_id,
    };

    return this.get(`agendamento/datas-geral`, query);
  }

  getHorariosDisponiveis(query: any) {
    return this.get(`agendamento/horarios`, query);
  }

  insertAgenda(agendamento: FinalizarAgendamento) {
    return this.patch(`agendamento/agendar`, agendamento);
  }

  updateAgenda(agendamento: FinalizarAgendamento) {
    return this.patch(`agendamento/${agendamento.agendamento_id}`, agendamento);
  }

  getAgendamento(agendamentoId) {
    return this.get(`agendamento/beneficiario/${agendamentoId}`);
  }

  historicoAgendamentos(
    beneficiarioId: string,
    paramsHistoricoAgendamento: ParamsHistoricoAgendamento
  ) {
    return this.get(
      `clinica/${beneficiarioId}/historico`,
      paramsHistoricoAgendamento
    );
  }

  desmarcarAgenda(agendamento: string, motivo_id: string, motivo: string) {
    let params = {
      operador_id: 1,
      agendamento_id: agendamento,
      motivo_id,
      motivo,
    };
    return this.put(`agendamento/cancelar`, params);
  }

  getTipoConsulta() {
    return this.get('agendamento/consulta/tipos');
  }

  getTipoAtendimento() {
    return this.get('agendamento/atendimento/tipos');
  }

  getComplementos() {
    return this.get('agendamento/atendimento/complementos');
  }

  getMotivosCancelamento() {
    return this.get(`agendamento/cancelamento/lista-motivos`);
  }

  sendLinkTeleliv(acessoTeleliv: IAcessoTeleliv) {
    return this.post_v3(`v3/telemedicine/schedules/${acessoTeleliv.agendamento_id}/sms`, { ...acessoTeleliv })
  }

  desassociarGuia(agendamento_id: string, data: any) {
    return this.put(`clinica/agendamentos/${agendamento_id}/desassociar-guia`, { ...data, agendamento_id })
  }
}
