import { PipesModule } from 'projects/clinicas-liv/src/app/shared/pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { RecipientRoutingModule } from './recipient-routing.module';
import { LOCALE_ID, NgModule } from '@angular/core';
import localePt from '@angular/common/locales/pt';
import { CommonModule, registerLocaleData } from '@angular/common';
import { RecipientComponent } from './recipient.component';
import { HeaderModule } from '../../shared/components/header/header.module';
import { SharedModule } from '../../shared/shared.module';
import { RecipientListComponent } from './pages/recipient-list/recipient-list.component';
import { LoadingComponent } from './components/loading/loading.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClientModule } from '@angular/common/http';
import { NgxMaskModule } from 'ngx-mask';
import { RecordComponent } from './components/record/record.component';
import { ModalConfirmationComponent } from './components/modal-confirmation/modal-confirmation.component';
import { MyautofocusdirectiveDirective } from './directives/myautofocusdirective.directive';
import { FormularioGestanteModule } from '../layout-default/modules/formulario-gestante/formulario-gestante.module';
import { AutocompleteDirective } from './directives/autocomplete/autocomplete.directive';
import { CepDirective } from './directives/cep/cep.directive';

registerLocaleData(localePt);
@NgModule({
  declarations: [
    RecipientComponent,
    RecipientListComponent,
    LoadingComponent,
    RecordComponent,
    ModalConfirmationComponent,
    MyautofocusdirectiveDirective,
    AutocompleteDirective,
    CepDirective,
  ],
  imports: [
    RecipientRoutingModule,
    CommonModule,
    FormsModule,
    HeaderModule,
    SharedModule,
    NgSelectModule,
    HttpClientModule,
    PipesModule,
    NgxMaskModule.forRoot(),
    FormularioGestanteModule
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR',
    },
  ],
})
export class RecipientModule {}
