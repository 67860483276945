import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'projects/clinicas-liv/src/app/shared/services/storage/storage.service';
import { PathService } from '../../../core/services/path/path.service';
import { StateHeaderService } from '../../../shared/services/state-header/state-header.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public usuario: any;
  public hoje: Date;

  constructor(
    private router: Router,
    private pathService: PathService,
    private storageService: StorageService,
    public stateHeaderService: StateHeaderService
  ) {}

  ngOnInit(): void {
    this.usuario = this.storageService.getUsuario();
    this.stateHeaderService.getAmbulatorio();
    this.stateHeaderService.getGuiche();
    this.atualizaDataAtual();
  }

  atualizaDataAtual() {
    setInterval(() => {
      this.hoje = new Date();
    }, 1000);
  }

  navigateInicio() {
    this.pathService.navigateTelaInicial();
  }

  navigateTelaEscolhaAmbulatorio() {
    this.pathService.navigateTelaEscolhaAmbulatorio();
  }

  logout() {
    this.storageService.clear();
    this.router.navigate(['/']);
  }

  navigateToSettings(option = '') {
    this.router.navigate([`settings/${option}`]);
  }
}
