import { Pregnancy } from './../../layout-default/modules/formulario-gestante/cadastro-gestante/models/pregnancy.model';
import { Email } from './email.model';
export interface IRecipient {
  id?: string
  rg: string
  name: string
  social_name: string
  mother_name: string
  gender: string
  birthdate: string
  cpf: string
  phones: any[]
  emails: Email[]
  recipients: any[]
  marital_status: string
  address: IAddress
  particular_plan?: boolean
  observations: string
  pregnancies?: Pregnancy
}

export interface IAddress {
  zip_code: string
  state: string
  city: string
  type: string
  public_place: string
  number: string
  neighborhood: string
  district?: string
  complement: string
}

export class Recipient {
  id?: ''
  rg: ''
  name: ''
  social_name: ''
  mother_name: ''
  gender: ''
  birthdate: ''
  cpf: ''
  phones: []
  emails: []
  recipients: []
  marital_status: ''
  address: Address
  particular_plan?: boolean
  observations: ''
  pregnancies?: Pregnancy
}
export class Address {
  zip_code: ''
  state: ''
  city: ''
  type: ''
  public_place: ''
  number: ''
  neighborhood: ''
  district?: ''
  complement: ''
}
