import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private toastr: ToastrService) {}

  success(mensagem) {
    this.toastr.success(mensagem, 'Bom trabalho!');
  }

  error(mensagem) {
    this.toastr.error(mensagem, 'Opss..!');
  }

  info(mensagem) {
    this.toastr.info(mensagem, 'Informação!');
  }
}
