import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class PathService {
  constructor(private router: Router) {}

  navigateBack() {
    let novaRota: string;
    let routerList: any[] = this.router.url.split('/');
    let numeroPath: number = routerList.length - 1;

    // Lógica para retirar última nível de rota
    routerList.map((element, index) => {
      if (index < numeroPath) {
        if (novaRota) {
          novaRota = `${novaRota}/${element}`;
        } else {
          novaRota = `${element}`;
        }
      }
    });
    // Lógica para retirar última nível de rota Fim

    this.router.navigate([novaRota]);
  }

  navigateProsseguirAgendamento(beneficiarioId: string) {
    this.router.navigate([
      `/inicio/ambulatorio/realizar-agendamento/${beneficiarioId}`,
    ]);
  }

  navigateFinalizarAgendamento(beneficiarioId: string) {
    this.router.navigate([
      `/inicio/ambulatorio/realizar-agendamento/${beneficiarioId}/finalizar-solicitacao`,
    ]);
  }

  navigateAgendamento(beneficiarioId: string, agendamentoId: string) {
    this.router.navigate([
      `/inicio/ambulatorio/realizar-agendamento/${beneficiarioId}/${agendamentoId}`,
    ]);
  }

  navigateAgendamentoHistorico(beneficiarioId: string, agendamentoId: string) {
    this.router.navigate([
      `/inicio/ambulatorio/buscar-agendamentos/${beneficiarioId}/${agendamentoId}`,
    ]);
  }

  navigateBuscaBeneficiario() {
    this.router.navigate(['/inicio/ambulatorio/realizar-agendamento']);
  }

  navigateTelaTriagem(agendamentoId: string) {
    this.router.navigate([`/inicio/ambulatorio/triagem/${agendamentoId}`]);
  }

  navigateTelaFilaSenha() {
    this.router.navigate(['/inicio/ambulatorio/fila-senha']);
  }

  navigateTelaInicial() {
    this.router.navigate(['/inicio/ambulatorio']);
  }

  navigateTelaEscolhaAmbulatorio() {
    this.router.navigate(['/inicio']);
  }

  navigateTelaFiltroBuscarAgendamentos(beneficiarioId: string) {
    this.router.navigate([
      `/inicio/ambulatorio/buscar-agendamentos/${beneficiarioId}`,
    ]);
  }

  navigateFormGestanteChoiseBenef() {
    this.router.navigate(['/inicio/ambulatorio/formulario-gestante']);
  }

  navigateFormGestante(beneficiarioId: string) {
    this.router.navigate([
      `/inicio/ambulatorio/formulario-gestante/${beneficiarioId}`,
    ]);
  }
}
