import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  pipe(request) {
    return request.pipe(
      tap(() => {}),
      take(1)
    );
  }

  login(login) {
    return this.pipe(
      this.http.post(`${this.baseUrl}auth/login/clinica`, login)
    );
  }
}
