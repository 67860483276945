import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IComment } from '../../models/posts.model';
import { Observable } from 'rxjs';
import { retryWhen, delay, scan } from 'rxjs/operators';
import { Toast } from '../../utils/toast/toast';
import { environment } from 'projects/clinicas-liv/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CommentService {
  url: string;
  token: string;
  retrying: boolean;
  constructor(private http: HttpClient, private toast: Toast) {
    this.retrying = false;
    this.token = '';
    this.url = environment.apiV3;
  }

  // setUrl(url: string) {
  //   this.url = url;
  // }

  setToken(token: string){
    this.token = token;
  }

  create(comments: any, schenduling_id: string): Observable<IComment> {
    return this.http
      .post<IComment>(`${this.url}/v3/pep/schedules/${schenduling_id}/comments`, comments, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      })
      .pipe(
        retryWhen((errors: any) =>
          errors.pipe(
            delay(3000),
            scan((count: number) => {
              if (count >= 3) {
                this.retrying = false;
                throw errors;
              } else {
                this.retrying = true;
                count++;
              }
              return count;
            }, 0)
          )
        )
      );
  }

  getAll(schenduling_id: string): Observable<IComment[]> {
    return this.http
      .get<IComment[]>(`${this.url}/v3/pep/schedules/${schenduling_id}/comments`, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      })
      .pipe(
        retryWhen((errors: any) =>
          errors.pipe(
            delay(3000),
            scan((count: number) => {
              if (count >= 3) {
                throw errors;
              } else {
                count++;
              }
              return count;
            }, 0)
          )
        )
      );
  }
}
