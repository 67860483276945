import { PhoneService } from '../../services/phone/phone.service';
import { Phone } from '../../models/phone.model';
import { EmailService } from '../../services/email/email.service';
import { IRecipient } from '../../models/recipient.model';
import { Email } from '../../models/email.model';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  ViewChild,
  HostListener,
} from '@angular/core';
import { RecipientService } from '../../services/recipient/recipient.service';
import { Toast } from 'projects/clinicas-liv/src/app/shared/utils/toast/toast';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-record',
  templateUrl: './record.component.html',
  styleUrls: ['./record.component.scss'],
})
export class RecordComponent implements OnInit {
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  @Input() data: IRecipient;
  @Input() editTwo: boolean;
  @ViewChild('rgRef') rgRef;
  @ViewChild('cpfRef') cpfRef;
  @HostListener('document:keydown', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ) {
    if (event.key === 'Escape' || event.key === 'Esc') {
      this.showModalConfirmation()
    }
  }

  recipient: IRecipient;

  genders: any[];
  ufs: any[];
  maritalStatus: any[];
  emails: Email[];
  phones: any[];
  disabled: boolean;
  modalConfirmation: boolean;
  closeModalConfirmation: boolean;
  modalExistRecipient: boolean;
  edit: boolean;
  loading: boolean;
  titleModal: string;
  descripitionModal: string;
  confirmation_mail: string;
  mask: {
    cpf: string;
    phone: string;
    phone_fix: string;
    cep: string;
  };
  message: string;

  initialState: IRecipient = {
    id: '',
    name: '',
    cpf: '' || null,
    rg: '',
    birthdate: '',
    social_name: '',
    gender: '',
    marital_status: '',
    address: {
      zip_code: '',
      state: '',
      city: '',
      type: '',
      public_place: '',
      number: '',
      neighborhood: '',
      complement: '',
    },
    mother_name: '',
    phones: [
      {
        phone_number: '',
        main: true,
      },
      {
        phone_number: '',
        main: false,
      },
    ],
    emails: [
      {
        email: '',
        main: true,
        person_id: null,
      },
      {
        email: '',
        main: false,
        person_id: null,
      },
    ],
    recipients: [],
    particular_plan: false,
    observations: '',
  };

  constructor(
    private service: RecipientService,
    private toast: Toast,
    private emailService: EmailService,
    private phoneService: PhoneService
  ) {
    this.confirmation_mail = '';
    this.titleModal = '';
    this.descripitionModal = '';
    this.message = '';
    this.edit = false;
    this.modalConfirmation = false;
    this.modalExistRecipient = false;
    this.loading = false;
    this.emails = [
      {
        id: null,
        email: '',
        main: true,
        person_id: '',
      },
      {
        id: null,
        email: '',
        main: false,
        person_id: '',
      },
    ];
    this.phones = [
      {
        id: null,
        phone_number: '',
        main: true,
        person_id: '',
      },
      {
        id: null,
        phone_number: '',
        main: false,
        person_id: '',
      },
    ];
    this.genders = [
      { value: 'M', name: 'Masculino' },
      { value: 'F', name: 'Feminino' },
      { value: 'N', name: 'Não Informado' },
    ];
    this.ufs = [
      { value: 'AC', name: 'Acre' },
      { value: 'AL', name: 'Alagoas' },
      { value: 'AP', name: 'Amapá' },
      { value: 'AM', name: 'Amazonas' },
      { value: 'BA', name: 'Bahia' },
      { value: 'CE', name: 'Ceará' },
      { value: 'ES', name: 'Espírito Santo' },
      { value: 'GO', name: 'Goiás' },
      { value: 'MA', name: 'Maranhão' },
      { value: 'MT', name: 'Mato Grosso' },
      { value: 'MS', name: 'Mato Grosso do Sul' },
      { value: 'MG', name: 'Minas Gerais' },
      { value: 'PA', name: 'Pará' },
      { value: 'PB', name: 'Paraíba' },
      { value: 'PE', name: 'Pernambuco' },
      { value: 'PI', name: 'Piauí' },
      { value: 'RJ', name: 'Rio de Janeiro' },
      { value: 'RN', name: 'Rio Grande do Norte' },
      { value: 'RS', name: 'Rio Grande do Sul' },
      { value: 'RO', name: 'Rondônia' },
      { value: 'RR', name: 'Roraima' },
      { value: 'SC', name: 'Santa Catarina' },
      { value: 'SP', name: 'São Paulo' },
      { value: 'SE', name: 'Sergipe' },
      { value: 'TO', name: 'Tocantins' },
      { value: 'DF', name: 'Distrito Federal' },
    ];
    this.maritalStatus = [
      { value: 'S', name: 'Solteiro(a)' },
      { value: 'C', name: 'Casado(a)' },
      { value: 'V', name: 'Viúvo(a)' },
      { value: 'D', name: 'Divorciado(a)' },
      { value: 'U', name: 'União Estável' },
      { value: 'A', name: 'Separado(a)' },
      { value: 'O', name: 'Outro' },
    ];
    this.mask = {
      cpf: '000.000.000-00',
      phone: '(00)0 0000-0000',
      phone_fix: '(00) 0000-0000',
      cep: '00000-000',
    };
    this.recipient = this.initialState;
    this.disabled = true;
  }
  ngOnInit(): void {
    this.checkData();
    if (this.data) {
      this.recipient = this.sanitize(this.data);
      this.recipient.particular_plan = this.isParticular();
      this.edit = true;
    }
  }

  sanitize(recipient: IRecipient): IRecipient {
    if (recipient.emails.length <= 0) {
      recipient.emails = this.emails.map((one) => ({
        ...one,
        person_id: recipient.id,
      }));
    }
    if (recipient.emails.length == 1) {
      recipient.emails.push({ ...this.emails[0], person_id: recipient.id });
    }

    if (recipient.phones.length <= 0) {
      recipient.phones = this.phones.map((one) => ({
        ...one,
        person_id: recipient.id,
      }));
    }
    if (recipient.phones.length == 1) {
      recipient.phones.push({ ...this.phones[0], person_id: recipient.id });
    }
    return recipient;
  }

  isEmail(email): boolean {
    return email != '';
  }

  isPhone(phone): boolean {
    return phone != '';
  }

  createOrUpdateRecipient() {
    const recipient = { ...this.recipient };

    if (recipient.phones.length > 0) {
      recipient.phones = recipient.phones.filter((p) =>
        this.isPhone(p.phone_number)
      );
      if (recipient.phones.length > 0) {
        recipient.phones[0].main = true;
      }
    }
    if (recipient.emails.length > 0) {
      recipient.emails = recipient.emails.filter((e) => this.isEmail(e.email));
      if (recipient.emails.length > 0) {
        recipient.emails[0].main = true;
      }
    }

    if (this.edit) {
      this.message = 'Dados atualizados com sucesso';
    } else {
      this.message = 'Cadastro realizado com sucesso';
    }
    this.loading = true;
    this.service.create(recipient).subscribe(
      (result) => {
        this.loading = false;
        this.close(false);
        this.toast.success({
          title: '',
          message: `${this.message}`,
        });
        this.edit = false;
      },
      (error: HttpErrorResponse) => {
        this.loading = false;
        this.toast.error({
          title: '',
          message: 'Erro ao salvar as informações!',
        });
      }
    );
  }

  mergePhone(phone: Phone) {
    if (this.isPhone(phone.phone_number)) {
      if (phone.id) {
        this.phoneService.patch(phone).subscribe(
          () => {
            this.toast.success({
              title: '',
              message: 'Telefone atualizado com sucesso',
            });
          },
          (error: HttpErrorResponse) => {
            this.toast.error({
              title: '',
              message: 'Erro ao atualizar os dados do Telefone!',
            });
          }
        );
      } else {
        if (this.recipient.id) {
          phone.person_id = this.recipient.id;
          this.phoneService.post(phone).subscribe(
            (result) => {
              this.toast.success({
                title: '',
                message: 'Telefone cadastrado com sucesso',
              });
              phone.id = result.id;
            },
            (error: HttpErrorResponse) => {
              this.toast.error({
                title: '',
                message: 'Erro ao cadastrar os dados do Telefone!',
              });
            }
          );
        }
      }
    }
  }

  deletePhone(phone: any, index: number) {
    this.phoneService.delete(phone).subscribe(
      () => {
        phone.phone_number = '';
        this.recipient.phones[index] = this.phones[index];
        this.toast.success({
          title: '',
          message: 'Telefone deletado com sucesso',
        });
      },
      (error: HttpErrorResponse) => {
        this.toast.error({
          title: '',
          message: 'Erro ao deletar o Telefone!',
        });
      }
    );
  }

  mergeEmail(email: Email) {
    if (this.isEmail(email.email)) {
      if (email.id && this.confirmEmail()) {
        this.emailService.patch(email).subscribe(
          (result) => {
            this.toast.success({
              title: '',
              message: 'Email atualizado com sucesso',
            });
          },
          (error: HttpErrorResponse) => {
            this.toast.error({
              title: '',
              message: 'Erro ao atualizar os dados do email!',
            });
          }
        );
      } else {
        if (this.recipient.id && this.confirmEmail()) {
          email.person_id = this.recipient.id;
          this.emailService.post(email).subscribe(
            (result) => {
              email.id = result.id;
              this.toast.success({
                title: '',
                message: 'Email cadastrado com sucesso',
              });
            },
            (error: HttpErrorResponse) => {
              this.toast.error({
                title: '',
                message: 'Erro ao cadastrar os dados do email!',
              });
            }
          );
        }
      }
    }
  }

  deleteEmail(email: any, index: number) {
    this.emailService.delete(email).subscribe(
      () => {
        email.email = '';
        email.id = null;
        this.recipient.emails[index] = this.emails[index];
        this.toast.success({
          title: '',
          message: 'Email deletado com sucesso',
        });
      },
      (error: HttpErrorResponse) => {
        this.toast.error({
          title: '',
          message: 'Erro ao deletar o email!',
        });
      }
    );
  }

  checkRecipient() {
    if (this.recipient?.cpf?.length === 11 && !this.recipient.rg) {
      this.loading = true;
      this.service.getSingle(this.recipient?.cpf).subscribe(
        (result) => {
          this.loading = false;
          this.edit = true;
          this.rgRef.nativeElement.blur();
          this.recipient = this.sanitize(result);
          this.warningRecipient();
          this.recipient.particular_plan = this.isParticular();
        },
        (error: HttpErrorResponse) => {
          if (error.status == 404 && error.message) {
            this.disabled = false;
          } else {
            this.recipient = this.initialState;
            this.recipient.cpf = '';
          }
          this.loading = false;
        }
      );
    }
  }

  warningRecipient() {
    if (this.recipient.rg) {
      this.titleModal = 'Já existe cadastro com este CPF';
      this.descripitionModal =
        'Atenção, existem dados cadastrados com este CPF. Deseja fazer alguma alteração nesse cadastro?';
      this.modalExistRecipient = true;
    }
  }

  checkData() {
    if (this.data) {
      this.disabled = false;
    }
  }

  blockInput() {
    this.recipient.cpf = null;
    this.disabled = true;
  }

  releaseCampRg() {
    if (this.recipient?.cpf?.length < 11 || this.recipient?.cpf == null) {
      return true;
    }
    return false;
  }

  showModalConfirmation() {
    if (this.recipient.rg) {
      this.modalConfirmation = true;
      this.titleModal = 'Deseja sair da tela de cadastro ?';
      this.descripitionModal =
        'Atenção, saia somente quando houver à certeza que os dados foram salvos ou inalterados.';
    } else {
      this.closeModal.emit(false);
    }
  }

  closeModalRecipient($event: boolean) {
    if ($event) {
      this.modalExistRecipient = false;
      this.recipient = this.initialState;
      this.recipient.cpf = '';
      this.cpfRef.nativeElement.focus();
    } else {
      this.modalExistRecipient = false;
      this.disabled = false;
      this.edit = true;
    }
  }

  close($event: boolean) {
    if ($event) {
      this.modalConfirmation = false;
      this.disabled = false;
    } else {
      this.modalConfirmation = false;
      this.closeModal.emit(false);
      this.recipient = null;
    }
  }

  fetchAddress() {
    this.service
      .getAdress(this.recipient.address.zip_code)
      .subscribe((result) => {
        if (!Array.isArray(result)) {
          this.recipient.address = result;
        }
      });
  }

  checkFlag(value: boolean) {
    this.recipient.particular_plan = value;
  }

  isParticular() {
    return !!this.recipient.recipients.find((plan) => plan.code == '000');
  }

  confirmEmail(){
    return (
      this.recipient.emails[0].email.toLocaleUpperCase() ===
      this.confirmation_mail.toLocaleUpperCase()
    );
  }
}
