<div id="Login">
  <section class="welcome-section">
    <h1>
      Bem-vindo ao <br />
      <span>Clínicas</span>
    </h1>
    <img src="assets/images/login-logo.svg" alt="LIV Saúde" class="logo" />
  </section>
  <section class="right">
    <h1>Faça seu <span>login</span></h1>
    <form (keydown)="formEnter($event)">
      <div class="material-input my-1">
        <label>
          <input
            id="input-user"
            type="text"
            aria-describedby="usuario"
            name="usuario"
            [(ngModel)]="usuario"
            required
          />
          <span class="placeholder">Usuário</span>
        </label>
      </div>

      <div class="material-input input-wrapper my-1">
        <label>
          <input
            [type]="visualizarSenha ? 'text' : 'password'"
            name="senha"
            id="input-senha"
            [(ngModel)]="senha"
            required
          />
          <span class="placeholder">Senha</span>
          <i
            id="olhinho-senha"
            class="cursor-pointer fas {{
              visualizarSenha ? 'fa-eye-slash' : 'fa-eye'
            }}  input-icon"
            (click)="mostrarSenha()"
          ></i>
        </label>
      </div>

      <small
        id="login-error"
        *ngIf="mensagemErro"
        class="d-flex text-danger font-weight-bold"
      >
        {{ mensagemErro }}
      </small>

      <button
        id="btn-login"
        type="button"
        (click)="logarNoSistema()"
        class="system-login global-button d-flex align-items-center"
        [disabled]="!formularioValido() || loadingRequest"
      >
        Acessar

        <div
          *ngIf="loadingRequest"
          class="spinner-border spinner-border-sm ml-2"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </button>

      <div
        class="d-flex justify-content-center mt-3 cursor-pointer"
        (click)="openModalChangelog()"
      >
        <small class="text-primary font-weight-bold">{{ version }}</small>
      </div>
    </form>
  </section>
</div>

<app-modal-changelog
  (setVersion)="setVersion($event)"
  [openModal]="openDialogChangelog"
  (closeModal)="closeModalChangelog()"
>
</app-modal-changelog>
