<div class="modal-backdrop-introdution">
  <div id="modal-form-introdution">
    <div>
      <h6>{{title}}</h6>
      <div id="close-container">
        <button (click)="closeButton()">
          <span class="material-icons-outlined">close</span>
        </button>
      </div>
    </div>
    <p>
      {{description}}
    </p>
    <div class="button-modal-introdution">
      <button (click)="close()">Sim</button>
      <button (click)="noClose()">Não</button>
    </div>
  </div>
</div>
