import { Component,Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'schendule-close',
  templateUrl: './close.component.html',
  styleUrls: ['./close.component.scss']
})
export class CloseComponent {

  @Output() onClose: EventEmitter<any> = new EventEmitter();
  constructor() { }

  close(){
    this.onClose.emit();
  }
}
