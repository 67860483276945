<section class="inputs">
  <section class="controler-search">
    <button>
      <i class="fas fa-search"></i>
    </button>
    <input
      [id]="inputId"
      [mask]="mask"
      type="text"
      [placeholder]="label"
      name="text"
      autocomplete="off"
      [ngModel]="sharedVar"
      (ngModelChange)="change($event)"
      required
    >
  </section>
</section>
