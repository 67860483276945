import {
  Address,
  IRecipient,
  Recipient,
} from './../../../../recipient/models/recipient.model';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PregnancyService } from './services/pregnancy.service';
import { Pregnancy } from './models/pregnancy.model';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
} from '@angular/forms';
import { RecipientService } from '../../../../recipient/services/recipient/recipient.service';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';

interface SelectBool {
  value: boolean;
  name: string;
}
interface SelectString{
  value: string;
  name: string;
}
interface SelectNumber{
  value: number;
  name: string;
}
@Component({
  selector: 'app-cadastro-gestante',
  templateUrl: './cadastro-gestante.component.html',
  styleUrls: ['./cadastro-gestante.component.scss'],
})
export class CadastroGestanteComponent implements OnInit {
  @Input() recipient: Recipient;
  isSucess: boolean;
  pregnancies = {} as Pregnancy[];
  pregnancy: Pregnancy;
  formGestante: any;
  risks: SelectBool[];
  diabetes: SelectString[];
  parts: SelectString[];
  indapgar: SelectNumber[];
  @Output() closeModal: EventEmitter<any> = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private service: PregnancyService,
    private recipientService: RecipientService
  ) {
    this.isSucess = false;
    this.formGestante = this.formBuilder.group({
      diabetes_militus: ['', Validators.required],
      early_pregnancy: [Number, Validators.required],
      risk: [, Validators.required],
      hypertension: [''],
      apgar: [null],
      childbirth_type: [''],
      rn_weight: [null],
      childbirth_date: [],
      age: [],
      observations: [''],
      dpp: [Validators.required],
    });

    this.recipient = new Recipient();
    this.risks = [
      { value: true, name: 'Sim' },
      { value: false, name: 'Não' },
    ];
    this.diabetes = [
      { value: 'N', name: 'Não possui' },
      { value: 'Type 1', name: 'Tipo 1' },
      { value: 'Type 2', name: 'Tipo 2' },
    ];
    this.parts = [
      { value: 'Cesárea', name: 'Cesárea' },
      { value: 'Normal', name: 'Normal' },
    ];
    this.indapgar = [
      { value: 1, name: '1' },
      { value: 2, name: '2' },
      { value: 3, name: '3' },
      { value: 4, name: '4' },
      { value: 5, name: '5' },
      { value: 6, name: '6' },
      { value: 7, name: '7' },
      { value: 8, name: '8' },
      { value: 9, name: '9' },
      { value: 10, name: '10' },
    ];
  }

  ngOnInit(): void {
    //this.showId('7b3a859a-e88e-4262-b398-938f580c1aae')
    // this.service.get().subscribe((pregnancies: Pregnancy[]) => {
    //   this.pregnancy = pregnancies[pregnancies.length - 1];
    // });
  }

  showPregnancies() {
    this.service.get().subscribe((pregnancies: Pregnancy[]) => {
      this.pregnancies = pregnancies;
    });
  }

  closePregnancy() {
    this.closeModal.emit();
  }
  getRecipientId(id) {
    this.recipientService.getSingle(id);
  }

  updatePregnancy() {
    this.pregnancy = this.formGestante.value;
    this.pregnancy.recipient_id = this.recipient.id;
    this.service.update(this.recipient, this.pregnancy).subscribe(
      () => {
        this.toastr.success('Dados Salvos com sucesso');
        this.closeModal.emit();
      },
      (error: HttpErrorResponse) => {
        this.toastr.error('Erro no formato da data inserida.');
      }
    );
  }

  createPregnancy() {
    this.pregnancy = this.formGestante.value;
    // this.pregnancy.risk = this.formGestante.risk === 'true' ? true : false
    this.pregnancy.recipient_id = this.recipient.id;
    this.pregnancy.recipient_id;
    this.service.create(this.pregnancy).subscribe(() => {
      this.toastr.success('Dados Salvos com sucesso');
      this.closeModal.emit();
    },
      (error: HttpErrorResponse) => {
        this.toastr.error('Erro ao salvar os dados');
      }
    );
  }

  // deletePregnancy(pregnancy: Pregnancy) {
  //   this.service.delete(pregnancy).subscribe(() => {
  //     console.log('Deletei a pregnancy')
  //     this.service.get().subscribe((pregnancies: Pregnancy[]) => {
  //       this.pregnancy = pregnancies[pregnancies.length - 1];
  //       console.log(pregnancies)
  //     });
  //   });
  // }
  showSucess() {
    this.isSucess = true;
    try {
      if (!this.isSucess) {
        console.log('Deu Ruim');
      } else {
        this.toastr.success('Dados salvos com sucesso.');
      }
    } catch (error) {
      console.log(error);
    }
  }
}
