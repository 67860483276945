<table *ngIf="!loadingBeneficiarios && dados.length > 0">
  <tr>
    <th>Nome do beneficiário</th>
    <th>Plano</th>
    <th>Desc. do plano</th>
    <th>Tipo</th>
  </tr>

  <tbody>
    <tr
      class="tr-result"
      *ngFor="let item of dados; let i = index"
      [ngClass]="indexRegistroClicado === i ? 'text-primary' : ''"
      id="click-registro-{{ i }}"
      (click)="getLinhaClicada(item, i)"
      (dblclick)="prosseguirBeneficiarioDbl(item)"
    >
      <td>{{ item.nome }}</td>
      <td>{{ item.plano.codigo }}</td>
      <td>{{ item.plano.nome }}</td>
      <td>{{ item.titular }}</td>
    </tr>
  </tbody>
</table>
<div class="d-flex flex-row-reverse mt-3">
  <button
    id="btn-prosseguir"
    (click)="prosseguirBeneficiario()"
    class="global-button"
  >
    PROSSEGUIR
  </button>
</div>
