<div class="material-input">
  <label>
    <input
      [id]="inputId"
      [mask]="mask"
      type="text"
      placeholder=""
      name="text"
      autocomplete="off"
      [ngModel]="sharedVar" 
      (ngModelChange)="change($event)"
      required
    />
    <span class="placeholder">{{ label }}</span>
  </label>
</div>
