import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  transform(value: string, ...args: any[]): string {
    if (!value) {
      return null;
    }

    moment.locale('pt-BR');

    let LLLL = args.find((one) => one == 'LLLL');

    //Domingo, 5 de Julho de 2020 às 11:52
    if (LLLL) return moment(value).format('dddd, D [de] MMMM [de] YYYY[,] LTS');

    return moment(value).format('DD/MM/YYYY');
  }
}
