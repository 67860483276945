import { Injectable } from '@angular/core';
import * as moment from 'moment';
import 'moment/dist/locale/pt-br';
import { Agendamento } from '../../../shared/models/agendamento';
import { Beneficiario } from '../../../shared/models/beneficiario';
import { FiltroAgendamento } from '../../../shared/models/filtro-agendamento';
import { BaseService } from '../base/base.service';

@Injectable({
  providedIn: 'root',
})
export class LocalAtendimentoService extends BaseService {
  getAtendimentos(filtro: FiltroAgendamento, limite: number, pagina: number, withFiltro = true) {
    const data = new Date();
    let hoje = `${data.getFullYear()}-${data.getMonth() + 1 < 10 ? '0' + (data.getMonth() + 1) : data.getMonth() + 1}-${data.getDate() < 10 ? '0' + data.getDate() : data.getDate()}`;
    let query = {
      atendido: filtro?.atendido,
      nao_atendido: filtro?.nao_atendido,
      turno: filtro?.turno,
      disponivel: filtro?.disponivel,
      data: withFiltro ? (filtro.data ? filtro.data : hoje) : hoje,
      especialidade_id: null,
      especialidade_source_id: null,
      prestador_id: null,
      prestador_source_id: null,
      local_atendimento_id: null,
      local_atendimento_source_id: null,
      limite: limite,
      pagina: pagina,
    };

    if(filtro.especialidade_id) {
      if(filtro.especialidade_id.length > 30) {
        query.especialidade_id = filtro.especialidade_id;
      } else {
        query.especialidade_source_id = filtro.especialidade_id;
      }
    }
    if(filtro.prestador_id) {
      if(filtro.prestador_id.length > 30) {
        query.prestador_id = filtro.prestador_id;
      } else {
        query.prestador_source_id = filtro.prestador_id;
      }
    }
    if(filtro.local_atendimento_id) { 
      if(filtro.local_atendimento_id.length > 30) {
        query.local_atendimento_id = filtro.local_atendimento_id;
      } else {
        query.local_atendimento_source_id = filtro.local_atendimento_id;
      }
    }

    return this.get(`clinica/agendamentos`, query);
  }

  getLocaisAtendimentos(beneficiario: Beneficiario, parametros: Agendamento) {
    let query = {
      beneficiario_source_id: beneficiario.source_id,
      especialidade_source_id: parametros.especialidade?.source_id,
    };

    return this.get(`agendamento/locais-atendimentos`, query);
  }

  getLocaisAtendimentosAgendamentos() {
    return this.get(`clinica/agendamentos/locais-atendimentos`);
  }
}
