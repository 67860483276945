import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tags-atendimento',
  templateUrl: './tags-atendimento.component.html',
  styleUrls: ['./tags-atendimento.component.scss'],
})
export class TagsAtendimentoComponent implements OnInit {
  @Input() idTagAtendimento: number = null;
  @Input() descricaoTag: string;

  tagsAtendimento: any = [
    { id: 1, color: '#DDDDDD', corTexto: '#666666cc' },
    { id: 2, color: '#ff9029' },
    { id: 3, color: '#00CC30' },
    { id: 4, color: '#ff162c' },
    { id: 5, color: '#007ecc' },
    { id: 6, color: '#333333' },
    { id: 7, color: '#9c27b0' },
    { id: 8, color: '#009688' },
    { id: 9, color: '#607d8b' },
    { id: "liberado", color: '#38C172' },
    { id: "auditoria", color: '#3490DC' },
    { id: "aguardando_autorizacao", color: '#8795A1' },
    { id: "cancelada", color: '#EF5753' },
    { id: "aguardando_confirmacao", color: '#F6993F' },
    { id: "opme", color: '#794ACF' }
  ];

  constructor() {}

  ngOnInit(): void {
    this.getCorTexto();
  }

  getCorTexto() {
    let response = this.tagsAtendimento.filter(
      (item) => item.id === this.idTagAtendimento
    );
    return {
      cor: response[0]?.color,
      corTexto: response[0]?.corTexto,
      titulo: this.descricaoTag,
    };
  }
}
