<div class="modal-backdrop">
  <div id="modal-form">
    <div id="close-container">
      <button type="button" (click)="showModalConfirmation()">
        <span class="material-icons-outlined"> close </span>
      </button>
    </div>
    <form
      #registerUser="ngForm"
      (ngSubmit)="createOrUpdateRecipient()"
      (keydown.enter)="$event.preventDefault()"
    >
      <h1>Cadastro de Pessoa</h1>
      <div class="form-row">
        <div class="form-group col-4">
          <label for="cpf" class="label-form">CPF</label>
          <span class="required">*</span>
          <input
            myAutofocus
            type="text"
            class="form-control"
            [mask]="mask.cpf"
            minlength="11"
            #cpfRef
            #cpf="ngModel"
            name="cpf"
            autocomplete="off"
            [(ngModel)]="recipient.cpf"
            (blur)="checkRecipient()"
            required
          />
          <div *ngIf="cpf.invalid && (cpf.touched || cpf.dirty)">
            <p *ngIf="cpf.errors?.required">CPF é obrigatório</p>
            <p *ngIf="cpf.errors?.minlength">CPF digitado está incompleto</p>
          </div>
        </div>
        <div class="form-group col-4">
          <label for="rg" class="label-form">RG</label>
          <span class="required">*</span>
          <input
            #rgRef
            autocomplete="off"
            type="text"
            pattern="[0-9]+$"
            class="form-control"
            #rg="ngModel"
            [(ngModel)]="recipient.rg"
            name="rg"
            id="rg"
            maxlength="16"
            [disabled]="releaseCampRg()"
            required
          />
          <div *ngIf="rg.invalid && (rg.touched || rg.dirty)">
            <p *ngIf="rg.errors?.required">RG é obrigatório</p>
          </div>
        </div>
        <div class="form-group col-4">
          <label for="birthdate" class="label-form">Data de Nascimento</label>
          <span class="required">*</span>
          <input
            autocomplete="off"
            type="date"
            class="form-control"
            #date="ngModel"
            [(ngModel)]="recipient.birthdate"
            name="birthdate"
            [disabled]="disabled"
            required
          />
          <div *ngIf="date.invalid && (date.touched || date.dirty)">
            <p *ngIf="date.errors?.required">
              Data de nascimento é obrigatório
            </p>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-12">
          <label for="name" class="label-form">Nome</label>
          <span class="required">*</span>
          <input
            autocomplete="disabled"
            pattern="[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
            type="text"
            class="form-control"
            #name="ngModel"
            [(ngModel)]="recipient.name"
            minlength="8"
            maxlength="100"
            name="name"
            id="name"
            [disabled]="disabled"
            required
          />
          <div *ngIf="name.invalid && (name.touched || name.dirty)">
            <p *ngIf="name.errors?.required">Nome completo é obrigatório</p>
            <p *ngIf="name.errors?.minlength">
              Deve possuir no mínimo 8 caracteres
            </p>
            <p *ngIf="name.errors?.pattern">Deve possuir apenas letras</p>
          </div>
        </div>
      </div>

      <div
        class="form-row mt-2 mb-2"
        style="border-top: 1px solid #ccc; padding-top: 8px"
      >
        <div class="form-group col-12">
          <div
            class="
              form-row
              d-flex
              align-items-center
              justify-content-start
              mb-1
              ml-1
            "
          >
            <input
              type="checkbox"
              style="margin-top: 1px"
              (change)="
                checkFlag(
                  (recipient.particular_plan = !recipient.particular_plan)
                )
              "
              id="check"
              [checked]="recipient.particular_plan"
              [disabled]="isParticular()"
            />
            <label for="check" class="label-form ml-1"
              >Atendimento particular</label
            >
          </div>
        </div>
      </div>

      <div
        class="form-row"
        *ngFor="let plan of recipient.recipients; let i = index"
      >
        <div class="form-group col-2">
          <label for="code" class="label-form">Cód.Plano</label>
          <input
            autocomplete="disabled"
            type="text"
            class="form-control"
            disabled
            [value]="plan.code"
            id="code-{{ i }}"
            name="code"
          />
        </div>
        <div class="form-group col-10">
          <label for="plan" class="label-form">Plano</label>
          <input
            autocomplete="disabled"
            type="text"
            class="form-control"
            [value]="plan.plan | capitalize"
            disabled
            id="plan-{{ i }}"
            name="plan"
          />
        </div>
      </div>

      <div
        class="form-row"
        style="border-top: 1px solid #ccc; padding-top: 8px"
      >
        <div class="form-group col-4">
          <label for="nameSocial" class="label-form">Nome Social</label>
          <input
            autocomplete="disabled"
            pattern="[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
            type="text"
            class="form-control"
            maxlength="80"
            [(ngModel)]="recipient.social_name"
            name="socialName"
            [disabled]="disabled"
          />
        </div>
        <div class="form-group col-4">
          <label>Estado civil:</label>
          <span class="required">*</span>
          <ng-select
            [(ngModel)]="recipient.marital_status"
            name="maritalStatus"
            [disabled]="disabled"
            required
          >
            <ng-option
              *ngFor="let marital of maritalStatus"
              [value]="marital.value"
              >{{ marital.name }}</ng-option
            >
          </ng-select>
        </div>
        <div class="form-group col-4">
          <label>Sexo:</label>
          <span class="required">*</span>
          <ng-select
            [(ngModel)]="recipient.gender"
            name="gender"
            [disabled]="disabled"
            class="ng-select"
            required
          >
            <ng-option *ngFor="let gender of genders" [value]="gender.value">{{
              gender.name
            }}</ng-option>
          </ng-select>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-3">
          <div>
            <label for="cep" class="label-form">CEP</label>
            <span class="required">*</span>
            <a
              href="https://buscacepinter.correios.com.br/app/endereco/index.php"
              target="_blank"
              style="
                font-size: 12px;
                font-family: 'Muli', sans-serif;
                margin-left: 19px;
              "
              >Consulta CEP</a
            >
            <input
              Autocomplete
              autocomplete="disabled"
              type="text"
              class="form-control"
              #cep="ngModel"
              name="cep"
              [mask]="mask.cep"
              directiveCep
              ngModel
              [(ngModel)]="recipient.address.zip_code"
              required
              minlength="8"
              maxlength="9"
              [disabled]="disabled"
              (blur)="fetchAddress()"
            />
            <div *ngIf="cep.invalid && (cep.touched || cep.dirty)">
              <p *ngIf="cep.errors?.required">CEP é obrigatório</p>
              <p *ngIf="cep.errors?.minlength">CEP está incompleto</p>
            </div>
          </div>
        </div>
        <div class="form-group col-4">
          <label for="city">Cidade</label>
          <span class="required">*</span>
          <input
            Autocomplete
            autocomplete="disabled"
            type="text"
            class="form-control"
            name="city"
            #city="ngModel"
            [(ngModel)]="recipient.address.city"
            [disabled]="disabled"
            required
          />
          <div *ngIf="city.invalid && (city.touched || city.dirty)">
            <p *ngIf="city.errors?.required">Nome da cidade é obrigatório</p>
          </div>
        </div>
        <div class="form-group col-5">
          <label for="district" class="label-form">Bairro</label>
          <span class="required">*</span>
          <input
            Autocomplete
            autocomplete="disabled"
            type="text"
            class="form-control"
            name="district"
            #district="ngModel"
            [(ngModel)]="recipient.address.neighborhood"
            id="district"
            [disabled]="disabled"
            required
          />
          <div *ngIf="district.invalid && (district.touched || district.dirty)">
            <p *ngIf="district.errors?.required">
              Nome do bairro é obrigatório
            </p>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-2">
          <label for="type" class="label-form">Tipo:</label>
          <input
            autocomplete="disabled"
            name="type_logradouro"
            [disabled]="disabled"
            class="form-control"
            [(ngModel)]="recipient.address.type"
          />
        </div>
        <div class="form-group col-7">
          <label for="street">Logradouro</label>
          <span class="required">*</span>
          <input
            Autocomplete
            autocomplete="disabled"
            type="text"
            class="form-control"
            #street="ngModel"
            id="street"
            name="street"
            [(ngModel)]="recipient.address.public_place"
            [disabled]="disabled"
            required
          />
          <div *ngIf="street.invalid && (street.touched || street.dirty)">
            <p *ngIf="street.errors?.required">
              Nome do logradouro é obrigatório
            </p>
          </div>
        </div>
        <div class="form-group col-3">
          <label for="numberHouse" class="label-form">Número</label>
          <span class="required">*</span>
          <input
            autocomplete="disabled"
            type="number"
            class="form-control"
            #numberHouse="ngModel"
            name="numberHouse"
            [(ngModel)]="recipient.address.number"
            id="numberHouse"
            [disabled]="disabled"
            required
          />
          <div
            *ngIf="
              numberHouse.invalid && (numberHouse.touched || numberHouse.dirty)
            "
          >
            <p *ngIf="numberHouse.errors?.required">Número é obrigatório</p>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-8">
          <label for="complement" class="label-form">Complemento</label>
          <input
            Autocomplete
            autocomplete="disabled"
            [(ngModel)]="recipient.address.complement"
            type="text"
            class="form-control"
            id="complement"
            name="publicPlace"
            [disabled]="disabled"
          />
        </div>
        <div class="form-group col-4">
          <label for="state" class="ml-1">UF</label>
          <span class="required">*</span>
          <ng-select
            [(ngModel)]="recipient.address.state"
            #state="ngModel"
            name="state"
            [disabled]="disabled"
            class="ng-select"
            required
          >
            <ng-option *ngFor="let uf of ufs" [value]="uf.value">{{
              uf.name
            }}</ng-option>
          </ng-select>
          <div *ngIf="state.invalid && (state.touched || state.dirty)">
            <p *ngIf="state.errors?.required">Estado é obrigatório</p>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-12">
          <label for="mae" class="label-form">Nome da Mãe</label>
          <span class="required">*</span>
          <input
            autocomplete="disabled"
            pattern="[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
            type="text"
            class="form-control"
            id="mae"
            minlength="2"
            maxlength="100"
            #mother="ngModel"
            [(ngModel)]="recipient.mother_name"
            name="mother"
            [disabled]="disabled"
            required
          />
          <div *ngIf="mother.invalid && (mother.touched || mother.dirty)">
            <p *ngIf="mother.errors?.required">Nome da mãe é obrigatório</p>
            <p *ngIf="mother.errors?.minlength">
              Quantidade de caracteres inferior
            </p>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-12">
          <div class="d-flex alignt align-items-center justify-content-between">
            <label>E-mail principal</label>
            <button
              class="mr-2"
              type="button"
              (click)="deleteEmail(recipient.emails[0], 0)"
            >
              <i class="fas fa-trash text-danger"></i>
            </button>
          </div>
          <input
            autocomplete="disabled"
            Autocomplete
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z-A-Za]{2,3}$"
            type="email"
            class="form-control email"
            placeholder="ex: email@exemplo.com"
            name="email"
            #emailMain="ngModel"
            [(ngModel)]="recipient.emails[0].email"
            (blur)="mergeEmail(recipient.emails[0])"
            [disabled]="disabled"
          />
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-12">
          <div class="d-flex alignt align-items-center justify-content-between">
            <label>Confirmar e-mail</label>
          </div>
          <input
            Autocomplete
            autocomplete="disabled"
            type="email"
            class="form-control email"
            #emailOpt="ngModel"
            placeholder="ex: email@exemplo.com"
            [(ngModel)]="confirmation_mail"
            name="emailoptional"
            (blur)="mergeEmail(recipient.emails[0])"
            [disabled]="disabled"
          />
          <p *ngIf="!confirmEmail()">E-Mails não coincidem</p>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-6">
          <label>Telefone principal</label>
          <span class="required">*</span>
          <input
            autocomplete="disabled"
            type="text"
            class="form-control"
            placeholder="(00)0 0000-0000"
            mask="(00) 0000-0000 || (00) 0 0000-0000"
            name="numberPhone"
            #numberPhone="ngModel"
            [(ngModel)]="recipient.phones[0].phone_number"
            (blur)="mergePhone(recipient.phones[0])"
            [disabled]="disabled"
            required
          />

          <div
            *ngIf="
              numberPhone.invalid && (numberPhone.touched || numberPhone.dirty)
            "
          >
            <p *ngIf="numberPhone.errors?.required">
              Telefone principal é obrigatório
            </p>
          </div>
        </div>
        <div class="form-group col-6">
          <div class="d-flex alignt align-items-center justify-content-between">
            <label>Telefone opcional</label>
            <button
              class="mr-2"
              type="button"
              (click)="deletePhone(recipient.phones[1], 1)"
            >
              <i class="fas fa-trash text-danger"></i>
            </button>
          </div>
          <div class="optional_phone">
            <input
              autocomplete="disabled"
              type="text"
              class="form-control"
              placeholder="(00)0 0000-0000"
              mask="(00) 0000-0000 || (00) 0 0000-0000"
              name="numberPhoneOptional"
              [(ngModel)]="recipient.phones[1].phone_number"
              [disabled]="disabled"
              (blur)="mergePhone(recipient.phones[1])"
            />
          </div>
        </div>
      </div>

      <div class="form-row mt-1">
        <div class="form-group col-12">
          <label>Observações:</label>
          <textarea class="col-12" rows="3" [disabled]="disabled"></textarea>
        </div>
      </div>

      <div class="container-buttons">
        <div>
          <button type="reset" (click)="blockInput()">Limpar</button>
          <button
            type="submit"
            [disabled]="!registerUser.valid || !confirmEmail()"
          >
            Confirmar
          </button>
        </div>
      </div>
    </form>
  </div>
  <app-modal-confirmation
    *ngIf="modalConfirmation"
    (yes)="close($event)"
    (no)="close($event)"
    (buttonClose)="close(true)"
    [title]="titleModal"
    [description]="descripitionModal"
  ></app-modal-confirmation>
  <app-modal-confirmation
    *ngIf="modalExistRecipient"
    (yes)="closeModalRecipient($event)"
    (no)="closeModalRecipient($event)"
    (buttonClose)="closeModalRecipient($event)"
    [title]="titleModal"
    [description]="descripitionModal"
  ></app-modal-confirmation>
  <app-loading
    *ngIf="loading"
    style="z-index: 1000000; position: absolute"
  ></app-loading>
</div>
