import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal-confirmation',
  templateUrl: './modal-confirmation.component.html',
  styleUrls: ['./modal-confirmation.component.scss']
})
export class ModalConfirmationComponent {

  @Input() title: string;
  @Input() description: string;
  @Output() yes: EventEmitter<any> = new EventEmitter();
  @Output() no: EventEmitter<any> = new EventEmitter();
  @Output() buttonClose: EventEmitter<any> = new EventEmitter();

  constructor() { }

  close(){
    this.yes.emit(false);
  }

  closeButton(){
    this.buttonClose.emit(false);
  }

  noClose(){
    this.no.emit(true)
  }
}
