<div *ngIf="idRisco; else semRisco">
  <i
    data-toggle="tooltip"
    data-placement="bottom"
    title="{{ getRisco()?.tooltip }}"
    class="color-risco fas fa-exclamation-triangle"
    [ngStyle]="{ color: getRisco()?.cor }"
  >
  </i>
</div>

<ng-template #semRisco>
  -
</ng-template>
