import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'idade',
})
export class IdadePipe implements PipeTransform {
  transform(value: number, ...args: any[]): string {
    const now = moment(new Date()); // Data de hoje
    const past = moment(value); // Outra data no passado
    const duration = moment.duration(now.diff(past));

    // Mostra a diferença em dias
    const years = duration.asYears();
    if (value) {
      return `${Number(Math.floor(years))} anos`;
    } else {
      return null;
    }
  }
}
