import { Component, OnInit } from '@angular/core';
import { Router,  } from '@angular/router';

@Component({
  selector: 'app-recipient',
  templateUrl: './recipient.component.html',
  styleUrls: ['./recipient.component.scss']
})
export class RecipientComponent implements OnInit {

  constructor(private readonly router: Router) { }

  ngOnInit(): void {
    // this.router.navigate([`recipient/list`])
  }

}
