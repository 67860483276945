import { Component } from '@angular/core';

@Component({
  selector: 'schendule-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class SchenduleLoadingComponent {

  constructor() { }

}
