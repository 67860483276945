import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize',
})
export class CapitalizePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';
    if (typeof value === 'number') return value;
    if (value === 'CPF') return 'CPF';
    const list = ['CE', 'US', 'LIV', '(REDELIV)', 'ICC', 'CAC'];

    let aux = value
      .toLowerCase()
      .split(' ')
      .map((one) => {
        if (list.includes(one.toUpperCase())) {
          return one.toUpperCase();
        }

        if (one.length == 2 && one.toLowerCase() !== 'em') return one;

        let aux = one.split('-');
        if (aux.length > 1) {
          return aux
            .map((a) => a.charAt(0).toUpperCase() + a.slice(1).toLowerCase())
            .join('-');
        }

        return one.charAt(0).toUpperCase() + one.slice(1).toLowerCase();
      })
      .join(' ');

    return aux;
  }
}
