import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ChangeLogService } from 'projects/clinicas-liv/src/app/core/services/change-log/change-log.service';
import { NotificationsService } from '../../../core/services/notifications/notifications.service';
declare var $: any;

@Component({
  selector: 'app-modal-changelog',
  templateUrl: './modal-changelog.component.html',
  styleUrls: ['./modal-changelog.component.scss'],
})
export class ModalChangelogComponent implements OnInit {
  @Input() openModal: boolean;
  @Input() id: boolean;
  @Output() closeModal = new EventEmitter<any>();
  @Output() confirmarAcao = new EventEmitter<any>();
  @Output() setVersion = new EventEmitter<any>();
  loading: boolean;
  releases: any[] = [];

  constructor(
    private changeLogService: ChangeLogService,
    private notificacoesService: NotificationsService
  ) {}

  ngOnInit(): void {
    $(`#modal-changelog`).on('hidden.bs.modal', (e) => {
      this.hiddenModal();
    });
    this.getReleases();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.openModal) {
      this.showModal();
    } else {
      this.hiddenModal();
    }
  }

  getReleases() {
    this.loading = true;
    this.changeLogService.getReleases().subscribe(
      (res) => {
        this.loading = false;
        this.releases = res.message || [];
        if(this.releases.length)
          this.setVersion.emit(this.releases[0].versao);
      },
      (err) => {
        this.hiddenModal();
        this.loading = false;
        this.notificacoesService.error(
          err.response.data.message ||
            'Erro desconhecido ao buscar versões do projeto'
        );
      }
    );
  }

  formatVersion(versao) {
    versao.replace('v', '').trim();
    return versao;
  }

  showModal() {
    $('#modal-changelog').modal('show');
    this.getReleases();
  }

  hiddenModal() {
    $('#modal-changelog').modal('hide');
    this.closeModal.emit(true);
  }

  confirmarAcaoMethod() {
    this.confirmarAcao.emit();
    this.hiddenModal();
  }
}
