import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {

  transform(time: string, ...args: unknown[]): unknown {
    if(!time) return ""
    const [hours, minutes] = time.split(':');
    return `${hours}:${minutes}`;
  }

}
